import React from 'react';
import L from 'leaflet';
import 'leaflet-draw';
import Moment from 'moment';
import MaterialIcon from './../../components/MaterialIcon';
import PropTypes, { element } from 'prop-types';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Skeleton from '@material-ui/lab/Skeleton';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import RestartIcon from '@material-ui/icons/YoutubeSearchedFor';
import UpdateIcon from '@material-ui/icons/Update';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import NavigationIcon from '@material-ui/icons/Navigation';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';

import Chart from 'chart.js/auto';

import { notification } from 'antd';

import img from '../point/img';
import Config from '../../constants/appConfig';
import geo from '../../components/util/geo';
import tools from '../../components/util/tools';

import rainOn from '../../assets/images/rainOn.png';
import rainOff from '../../assets/images/rain.png';
import solarOn from '../../assets/images/solarOn.png';
import solarOff from '../../assets/images/solar.png';
import thermalInversionExample from '../../assets/images/thermalInversionExample.png';
import normalFlowExample from '../../assets/images/normalFlowExample.png';
import cloud from '../../assets/images/cloud.png';
import sun from '../../assets/images/sun.png';
import pressureForecast from '../../assets/images/pressureForecast.png';
import humidityForecast from '../../assets/images/humidityForecast.png';
import temperatureForecast from '../../assets/images/temperatureForecast.png';
import windDirectionForecast from '../../assets/images/windDirectionForecast.png';
import windSpeedForecast from '../../assets/images/windSpeedForecast.png';
import rainForecast from '../../assets/images/rainForecast.png';
import rainDay from '../../assets/images/rainDay.png';
import rainNight from '../../assets/images/rainNight.png';
import lua from '../../assets/images/lua.png';
import chuva from '../../assets/images/chuva.png';
import thermalInversion from '../../assets/images/thermalInversion.png';
import latitude from '../../assets/images/latitude.png';
import longitude from '../../assets/images/longitude.png';
import pressure from '../../assets/images/pressure.png';
import altitude from '../../assets/images/altitude.png';
import weatherData from '../../assets/images/weatherData.png';
import condicoes_climaticas from '../../assets/images/condicoes_climaticas.png';

import DeltaTHeat from '../../components/heat_graph/deltaTGraph';

import './style.css';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    secondary: {
        backgroundColor: '#2D3238',
    },
    primary: {
        backgroundColor: '#3f51b5',
    },
    tooltip: {
        maxWidth: 'none',
        padding: 0,
    },
    customTooltip: {
        maxWidth: 'none',
        padding: 0,
    },
    label: {
        textAlign: 'left',
        paddingBottom: '0.75rem',
    },
    input: {
        textAlign: 'center',
        paddingTop: '0.75rem',
    },
    inputStyle: {
        '& label.Mui-focused': {
            color: 'rgb(250, 125, 52)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(250, 125, 52)',
        },
        '& .MuiInput-input': {
            color: 'white',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'white',
        },
        '& .MuiInputLabel-root': {
            color: 'rgb(250, 125, 52)',
        },
    },
    popUpButton: {
        color: 'rgb(250, 125, 52)',
        border: `1px solid rgb(250, 125, 52)`,
        '&:hover': {
            color: 'rgb(250, 125, 52)',
        },
    },
    disabledButton: {
        backgroundColor: 'rgb(255 255 255 / 26%)',
        cursor: 'not-allowed',
        border: 'none',
        boxShadow: 'none'
    },
    customWidthTooltip: {
        maxWidth: 500,
    },
    tab: {
        minWidth: '5rem',
        padding: '0 0.25rem',
        backgroundColor: 'rgb(32 35 39)',
        color: 'white',
        '&.Mui-selected': {
            backgroundColor: '#FA7D34',
            color: '#ffffff',
            borderRadius: '2rem'
        },
    },
    tabDisabled: {
        minWidth: '5rem',
        padding: '0 0.25rem',
        backgroundColor: 'rgb(32 35 39)',
        color: 'gray',
    },
    tabsIndicator: {
        display: 'none',
    },
    inputDate: {
        '& input': {
            color: 'white',
        },
        '&:before': {
            borderBottom: '1px solid white',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid white',
        },
        '&:after': {
            borderBottom: '2px solid #FA7D34',
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
        '& .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    expandedMapStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
        margin: 0,
        padding: 0,
        overflow: 'hidden',
    },
    buttonNormalStyles: {
        position: 'absolute',
        top: '0.25rem',
        left: '19.25rem',
        zIndex: 10000
    },
    buttonFullScreenStyles: {
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        zIndex: 10000
    },
    mapCommonStyle: {
        width: '100%',
        height: '100%',
        borderRadius: '1rem',
        transition: 'border-radius 0.3s',
        border: 'none',
    },
    select: {
        margin: '0 1rem',
        width: '12.5rem',
        color: 'white',
        '&:before': {
            borderBottom: '2px solid white',
        },
        '&:after': {
            borderBottom: '2px solid orange',
        },
    },
    icon: {
        color: 'white',
    },
    menuItem: {
        textAlign: 'center',
        color: 'black',
    },
});

class WeatherCoordinate {
    constructor() {
        this.latForecast = '';
        this.lngForecast = '';
    }
}

class WeatherData {
    constructor() {
        this.lat = '';
        this.lng = '';
        this.height = '';
        this.datetime = '';
        this.pressure = '';
        this.humidity = '';
        this.air_humidity = '';
        this.temperature = '';
        this.air_temperature = '';
        this.wind_direction = '';
        this.wind_speed = '';
        this.data_plus = '';
        this.rain = '';
        this.solar = '';
        this.chartRef = React.createRef();
    }
}

class Map extends React.Component {
    constructor(props) {
        super(props);
        const today = new Date();
        const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
        const currentYear = today.getFullYear();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 6);

        this.state = {
            stations: [],
            stationData: [],
            idStationSelected: '',
            goTo: false,
            nameL: '',
            heightL: '',
            idL: '',
            idSourceL: '',
            idCompanyL: '',
            datetimeL: '',
            pressureL: '',
            humidityL: '',
            air_humidityL: '',
            temperatureL: '',
            air_temperatureL: '',
            wind_directionL: '',
            wind_speedL: '',
            delta_tL: '',
            rainL: '',
            solarL: '',
            addressStationL: '',
            thermalInversionL: '',
            lat: { value: '', error: false, input: '' },
            lng: { value: '', error: false, input: '' },
            latL: { value: '', error: false, input: '' },
            lngL: { value: '', error: false, input: '' },
            latitudeForecast: { value: '', error: false, input: '' },
            longitudeForecast: { value: '', error: false, input: '' },
            selectedDate: this.getLocalDate(),
            maxSelectedDate: new Date().toISOString().split('T')[0],
            selectedMonth: `${currentYear}-${currentMonth}`,
            selectedYear: currentYear.toString(),
            selectedWeekStart: sevenDaysAgo.toISOString().split('T')[0],
            selectedWeekEnd: today.toISOString().split('T')[0],
            selectedHistoricType: 'daily',
            selectedInfo: 'weatherChart',
            selectedStation: '',
            filterStations: [],
            filterStationData: [],
            stationName: '',
            flightConditions: '',
            autoUpdate: true,
            loading: true,
            isLoading: true,
            selectedTime: '',
            sliderData: [],
            weatherForecastData: [],
            weatherForecastCoordinate: [],
            consultForecastData: false,
            errorLocation: null,
            activeMarker: null,
            stationSelected: null,
            isOpenWeatherForecast: false,
            hoverForecast: false,
            isOpenStation: false,
            addressExists: '',
            noData: false,
            isMapExpanded: false,
            showData: true,
            monthlyData: [],
            yearlyData: [],
            weeklyData: [],
        };
    };

    barChart = null;
    mymap = null;
    featureGroupP = null;
    tempMarker = null;
    drawCreateToolbar = null;

    componentDidMount = () => {
        this.existingIcons = {};
        const { autoUpdate, activeMarker } = this.state;

        this.list_stations().then(() => {
            if (autoUpdate) {
                setTimeout(() => {
                    this.startAutoUpdate();
                }, 1500);
            }
        });

        this.icon = img;
        this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);

        L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

        this.featureGroupP = L.featureGroup().addTo(this.mymap);

        this.generateChart();

        this.mymap.on('draw:created', (e) => {
            let guid = tools.newGuid();
            e.layer.on("click", () => { this.handleClickP(e.layer, guid); });

            let tempMark = new WeatherCoordinate();
            tempMark.latForecast = e.layer._latlng.lat;
            tempMark.lngForecast = e.layer._latlng.lng;
            e.layer._leaflet_id = guid;
            const element = e.layer._latlng;

            if (activeMarker) {
                this.mymap.removeLayer(activeMarker);
            }

            let iconUrl = this.icon[24].img;
            let customIcon = L.icon({
                iconUrl: iconUrl,
                iconAnchor: [13, 33]
            });

            let newWeatherIcon = L.marker([element.lat, element.lng], { icon: customIcon }).addTo(this.mymap);

            this.setState({
                activeMarker: newWeatherIcon,
                latitudeForecast: { error: false, input: geo.toDMS(element.lat, 'latitude', 1), value: element.lat },
                longitudeForecast: { error: false, input: geo.toDMS(element.lng, 'longitude', 1), value: element.lng },
            });
        });

        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            };

            navigator.geolocation.getCurrentPosition(
                this.handleSuccessLocation,
                this.handleErrorLocation,
                options
            );
        } else {
            this.setState({ errorLocation: 'Localização não encontrada.' });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { selectedInfo, filterStations, latitudeForecast, longitudeForecast, monthlyData, yearlyData, weeklyData } = this.state;
        if (selectedInfo !== prevState.selectedInfo) {
            if (filterStations.length > 0) {
                this.generateChart();
            } else if (monthlyData.length > 0) {
                this.generateMonthlyChart();
            } else if (yearlyData.length > 0) {
                this.generateYearlyChart();
            } else if (weeklyData.length > 0) {
                this.generateWeeklyChart();
            }
        }

        if (latitudeForecast !== prevState.latitudeForecast && longitudeForecast !== prevState.longitudeForecast) {
            this.setState({ consultForecastData: false });
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };

        clearInterval(this.timer);
    };

    getLocalDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    handleSuccessLocation = (position) => {
        const { activeMarker } = this.state;

        const userLatitude = position.coords.latitude;
        const userLongitude = position.coords.longitude;

        this.setState(
            {
                latitudeForecast: { error: false, input: geo.toDMS(userLatitude, 'latitude', 1), value: userLatitude },
                longitudeForecast: { error: false, input: geo.toDMS(userLongitude, 'longitude', 1), value: userLongitude },
            },
            () => {
                this.weatherForecast();

                if (activeMarker) {
                    this.mymap.removeLayer(activeMarker);
                }

                let iconUrl = this.icon[24].img;
                let customIcon = L.icon({
                    iconUrl: iconUrl,
                    iconAnchor: [13, 33],
                });
                let newWeatherIcon = L.marker([userLatitude, userLongitude], {
                    icon: customIcon,
                }).addTo(this.mymap);

                this.setState({ activeMarker: newWeatherIcon });
            }
        );
    };

    handleErrorLocation = (error) => {
        this.setState({ errorLocation: error.message });
    };

    consultForecast = () => {
        const { consultForecastData, activeMarker } = this.state;

        if (consultForecastData) {
            if (this.drawCreateToolbar) {
                this.drawCreateToolbar.disable();
            }
            this.setState({ consultForecastData: false });
        } else {
            let img = this.icon[24].img ? this.icon[24].img : this.icon[20].img;
            let dIcon = L.icon({
                iconUrl: img,
                iconAnchor: [13, 33]
            });

            if (!this.drawCreateToolbar) {
                this.drawCreateToolbar = new L.Draw.Marker(this.mymap, { icon: dIcon });
                this.mymap.on(L.Draw.Event.CREATED, (event) => {
                    if (activeMarker) {
                        this.mymap.removeLayer(activeMarker);
                    }
                    const { layer } = event;
                    this.mymap.addLayer(layer);
                    this.setState({
                        activeMarker: layer,
                        latitudeForecast: { error: false, value: layer.getLatLng().lat },
                        longitudeForecast: { error: false, value: layer.getLatLng().lng },
                    }, () => {
                        this.weatherForecast();
                    });
                });
            }

            this.drawCreateToolbar.enable();
            this.setState({ consultForecastData: true, goTo: false });
        }
    };

    zoomInL = () => {
        this.mymap.zoomIn();
    };

    zoomOutL = () => {
        this.mymap.zoomOut();
    };

    handleChangeCoordinates = (key) => (event) => {
        const { latitudeForecast, longitudeForecast } = this.state;
        const newValue = geo.toCoordinate(geo.toFixDMS(event.target.value));
        const valueDMS = event.target.value;
        this.validate(key, event.target.value);

        if (!isNaN(newValue)) {
            if (key === 'latitudeForecast') {
                this.setState({
                    latitudeForecast: {
                        ...latitudeForecast,
                        input: event.target.value,
                        value: newValue,
                        error: false,
                    },
                });
            } else if (key === 'longitudeForecast') {
                this.setState({
                    longitudeForecast: {
                        ...longitudeForecast,
                        input: event.target.value,
                        value: newValue,
                        error: false,
                    },
                });
            }

            this.updateWeatherIconPosition(valueDMS, newValue);
        } else {
            notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_COORDINATE,
                icon: <MaterialIcon icon="error" className="text-danger" />,
            });
        }
    };

    updateWeatherIconPosition = (valueDMS, newValue) => {
        const { activeMarker, latitudeForecast, longitudeForecast } = this.state;

        if (activeMarker) {
            if (latitudeForecast.input === valueDMS) {
                activeMarker.setLatLng([newValue, longitudeForecast.value]);
            } else if (longitudeForecast.input === valueDMS) {
                activeMarker.setLatLng([latitudeForecast.value, newValue]);
            }
        }
    };

    toggleAutoUpdate = () => {
        const { autoUpdate } = this.state;
        if (autoUpdate) {
            this.stopAutoUpdate();
        } else {
            this.setState({ selectedHistoricType: 'daily' })
            this.startAutoUpdate();
        }

        this.setState(prevState => ({
            autoUpdate: !prevState.autoUpdate,
        }));
    };

    startAutoUpdate = () => {
        this.list_stations().then(() => {
            this.setState({ selectedDate: this.getLocalDate() }, () => {
                this.handleFilterDate();
            });

            this.timerListStations = setInterval(() => {
                this.list_stations();
            }, 300000);

            this.timerHandleFilterDate = setInterval(() => {
                this.setState({ selectedDate: this.getLocalDate() }, this.handleFilterDate);
            }, 300000);
        }).catch((error) => {
            console.error('erro:', error);
        });
    };

    stopAutoUpdate = () => {
        clearInterval(this.timerListStations);
        clearInterval(this.timerHandleFilterDate);
    };

    drawMarker = (stationIcon) => {
        let dIcon = L.icon({
            iconUrl: stationIcon.img,
            iconAnchor: stationIcon.type === 'O' ? [25, 30] : [13, 33],
            popupAnchor: [0, -25],
        });
        let newLatLng = new L.LatLng(stationIcon.lat, stationIcon.lng);

        let existingIcon = this.existingIcons[stationIcon.id_source];

        if (existingIcon) {
            this.featureGroupP.removeLayer(existingIcon);
        }

        let myLayer = L.marker(newLatLng, { icon: dIcon });

        myLayer.on("click", () => { this.handleClickP(myLayer, stationIcon.id_source); });
        myLayer.addTo(this.featureGroupP);

        this.existingIcons[stationIcon.id_source] = myLayer;
    };

    validate = (name, value) => {
        let update = this.state[name];
        if (name === 'lat' || name === 'latL' || name === 'latitudeForecast') {
            update.input = value;
            if (/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(N|S)/.test(value)) {
                update.value = geo.toCoordinate(geo.toFixDMS(value));
                update.error = false;
            } else {
                update.error = true;
            }
        } else if (name === 'lng' || name === 'lngL' || name === 'longitudeForecast') {
            update.input = value;
            if ((/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(W|E)/.test(value))
                || (/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(O|L)/.test(value))) {
                update.value = geo.toCoordinate(geo.toFixDMS(value));
                update.error = false;
            } else {
                update.error = true;
            }
        }

        this.setState(update);
    };

    showGoTo = () => {
        const { goTo } = this.state;
        if (goTo) {
            this.setState({ goTo: false });
        } else {
            let lat = { error: false, input: geo.toDMS(this.mymap.getCenter().lat, 'latitude', 1), value: this.mymap.getCenter().lat };
            let lng = { error: false, input: geo.toDMS(this.mymap.getCenter().lng, 'longitude', 1), value: this.mymap.getCenter().lng };
            this.setState({ goTo: true, lat, lng, consultForecastData: false });
            if (this.drawCreateToolbar) {
                this.drawCreateToolbar.disable();
            }
        }
    };

    showMapData = () => {
        const { showData } = this.state;
        if (showData) {
            this.setState({ showData: false });
        } else {
            this.setState({ showData: true });
        }
    };

    goToCoordinates = () => {
        const { lat, lng } = this.state;
        this.mymap.setView([lat.value, lng.value]);
        this.setState({ goTo: false });
    };

    list_stations = () => {
        if (this.state.stations && this.state.stations.length > 0) {
            this.state.stations.forEach((item) => this.getDataById(item.id, item.name));
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const apiUrl = Config.server + '/weather_source';
            const options = {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            };

            fetch(apiUrl, options)
                .then((res) => {
                    if (res.status !== 200) throw res;
                    return res.json();
                })
                .then(
                    (result) => {
                        if (result.length > 0) {
                            const mostRecentStation = result.reduce((mostRecent, station) =>
                                new Date(mostRecent.datetime) > new Date(station.datetime) ? mostRecent : station
                            );

                            this.setState({
                                stations: result,
                                idStationSelected: mostRecentStation.id
                            }, () => {
                                result.forEach((item) => this.getDataById(item.id, item.name));
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    },
                    (error) => {
                        if (error.status === 405) {
                            notification.open({
                                message: this.props.res.PERMISSAO,
                                description: this.props.res.PERMISSAO_MSG,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        } else if (error.status === 401 || error.status === 403) {
                            this.props.link('/login');
                        } else {
                            notification.open({
                                message: this.props.res.ERRO,
                                description: this.props.res.ERRO_MSG,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        }
                        reject(error);
                    }
                );
        });
    };

    handleClickP = (layer, id) => {
        const { idStationSelected, stationData } = this.state;
        if (id && id !== idStationSelected) {
            const selectedStation = stationData.find((item) => item.id_source === id);
            if (selectedStation) {
                this.setState({
                    idStationSelected: id,
                    goTo: false,
                    stationName: selectedStation.name,
                    consultForecastData: false
                });
                if (this.drawCreateToolbar) {
                    this.drawCreateToolbar.disable();
                }
            }

            for (let i = 0; i < stationData.length; i++) {
                const element = stationData[i];
                const deltaT = element.delta_t?.toFixed(1);

                let deltaTValue;
                if (deltaT != -99 && deltaT !== undefined && deltaT !== null) {
                    deltaTValue = deltaT;
                    if (deltaTValue >= 2 && deltaTValue <= 8) {
                        this.setState({ flightConditions: `Condições adequadas` });
                    } else if (deltaTValue > 10) {
                        this.setState({ flightConditions: `Condições inadequadas` });
                    } else {
                        this.setState({ flightConditions: `Condições arriscadas` });
                    }
                } else {
                    this.setState({ flightConditions: 'Sem conexão' });
                }
                if (element.id_source === id) {
                    const newState = {
                        idL: element.id,
                        idCompanyL: element.id_company,
                        idSourceL: element.id_source,
                        latL: { error: false, input: geo.toDMS(element.lat, 'latitude', 1), value: element.lat },
                        lngL: { error: false, input: geo.toDMS(element.lng, 'longitude', 1), value: element.lng },
                        thermalInversionL: Number(element.air_temperature) - 5 > Number(element.temperature) ? 'Grandes chances de estar ocorrendo inversão térmica' : Number(element.air_temperature) - 1 > Number(element.temperature) ? 'Pode estar ocorrendo inversão térmica' : 'Não está ocorrendo inversão térmica',
                        rainL: element.rain !== undefined ? element.rain : 'Sem conexão',
                        solarL: element.solar !== undefined ? element.solar : 'Sem conexão',
                        addressStationL: element.addressStation !== undefined ? element.addressStation : 'Sem conexão',
                        delta_tL: deltaTValue !== undefined ? deltaTValue : 'Sem conexão',
                        nameL: element.name !== -99 && element.name !== null ? element.name : 'Sem conexão',
                        heightL: element.height !== -99 && element.height !== null ? element.height.toFixed(0) : 'Sem conexão',
                        datetimeL: element.datetime !== -99 && element.datetime !== null ? Moment(element.datetime).format("DD/MM/YY HH:mm") : 'Sem conexão',
                        humidityL: element.humidity !== -99 && element.humidity !== null ? (element.humidity > 100 ? 100 : element.humidity.toFixed(0)) : 'Sem conexão',
                        air_humidityL: element.air_humidity !== -99 && element.air_humidity !== null ? (element.air_humidity > 100 ? 100 : element.air_humidity.toFixed(0)) : 'Sem conexão',
                        temperatureL: element.temperature !== -99 && element.temperature !== null ? element.temperature.toFixed(0) : 'Sem conexão',
                        air_temperatureL: element.air_temperature !== -99 && element.air_temperature !== null ? element.air_temperature.toFixed(0) : 'Sem conexão',
                        wind_directionL: element.wind_direction !== -99 && element.wind_direction !== null ? element.wind_direction.toFixed(0) : 'Sem conexão',
                        pressureL: element.pressure !== -99 && element.pressure !== null ? element.pressure.toFixed(0) : 'Sem conexão',
                        wind_speedL: element.wind_speed !== -99 && element.wind_speed !== null ? element.wind_speed.toFixed(0) : 'Sem conexão',
                    };

                    this.setState(newState);
                    this.tempMarker = element;
                    break;
                }
            }
        } else {
            this.tempMarker = null;
        }
    };

    handleListItemClick = (id) => {
        const { stationData } = this.state;

        this.setState({ stationSelected: id })

        let poly;
        let idStation;
        let lat;
        let lng;

        const selectedStation = stationData.find((item) => item.id_source === id);

        if (selectedStation) {
            poly = this.mymap._layers[selectedStation.id];
            idStation = selectedStation.id_source;
            lat = selectedStation.lat;
            lng = parseFloat(selectedStation.lng);
            this.setState({ stationName: selectedStation.name });

            const hourSlider = Moment(selectedStation.datetime).format("HH");
            this.setState({ selectedTime: hourSlider.replace(/^0+/, '') });
        }

        this.handleClickP(poly, idStation);
        if (lat && lng) {
            this.mymap.setView([lat, lng], 14);
        }
        this.clearFilters();
    };

    handleDateChange = (e) => {
        this.setState({ selectedDate: e.target.value, filterStations: [], isLoading: true, selectedTime: '' });
    };

    handleMonthChange = (e) => {
        this.setState({ selectedMonth: e.target.value, monthlyData: [], isLoading: true });
    };

    handleYearChange = (e) => {
        this.setState({ selectedYear: e.target.value, yearlyData: [], isLoading: true });
    };

    handleWeekChange = (event) => {
        const selectedWeekStart = new Date(event.target.value);
        const today = new Date();
        const potentialEndDate = new Date(selectedWeekStart);
        potentialEndDate.setDate(selectedWeekStart.getDate() + 6);
        const selectedWeekEnd = potentialEndDate > today ? today : potentialEndDate;

        this.setState({
            selectedWeekStart: selectedWeekStart.toISOString().split('T')[0],
            selectedWeekEnd: selectedWeekEnd.toISOString().split('T')[0],
            isLoading: true
        });
    };

    handleFilterDate = () => {
        const { selectedDate, stationData, stations, idStationSelected } = this.state;

        let stationId;
        let lat;
        let lng;
        const stationFilter = stations.filter(station => station.id == idStationSelected);

        stationFilter.forEach((item) => {
            stationId = item.id;
        });

        const findStation = stationData.filter((station) => station.id_source == stationId);

        if (findStation.length > 0) {
            lat = parseFloat(findStation[0]?.lat);
            lng = parseFloat(findStation[0]?.lng);
        }

        if (lat && lng) {
            this.mymap.setView([lat, lng]);
        }

        const today = this.getLocalDate()
        if (selectedDate !== today) {
            this.stopAutoUpdate();
            this.setState({ autoUpdate: false });
        }

        if (findStation.length > 0) {
            const station = findStation[0];

            this.list_stations_data(stationId, selectedDate);

            if (!station.delta_t) {
                this.setState({ flightConditions: `Sem conexão` });
            } else if (station.delta_t.toFixed(1) >= 2 && station.delta_t.toFixed(1) <= 8) {
                this.setState({ flightConditions: `Condições adequadas` });
            } else if (station.delta_t.toFixed(1) > 10) {
                this.setState({ flightConditions: `Condições inadequadas` });
            } else {
                this.setState({ flightConditions: `Condições arriscadas` });
            }
            this.setState({
                selectedInfo: 'weatherChart',
                datetimeL: Moment(station.datetime).format('DD/MM/YY HH:mm'),
                humidityL: station.humidity !== null ? station.humidity : '',
                air_humidityL: station.air_humidity !== null ? station.air_humidity : '',
                temperatureL: station.temperature !== null ? station.temperature : '',
                air_temperatureL: station.air_temperature !== null ? station.air_temperature : '',
                heightL: station.height !== null ? station.height : '',
                pressureL: station.pressure !== null ? station.pressure : '',
                wind_speedL: station.wind_speed !== null ? station.wind_speed : '',
                wind_directionL: station.wind_direction !== null ? station.wind_direction : '',
                delta_tL: station.delta_t !== null ? station.delta_t.toFixed(1) : '',
                thermalInversionL: Number(station.air_temperature) - 5 > Number(station.temperature)
                    ? 'Grandes chances de estar ocorrendo inversão térmica'
                    : Number(station.air_temperature) - 1 > Number(station.temperature)
                        ? 'Pode estar ocorrendo inversão térmica'
                        : 'Não está ocorrendo inversão térmica',
                solarL: station.solar !== null ? station.solar.toFixed(1) : '',
                rainL: station.rain !== null ? station.rain : '',
                selectedTime: ''
            });
        } else {
            this.setState({
                datetimeL: '',
                humidityL: '',
                air_humidityL: '',
                temperatureL: '',
                air_temperatureL: '',
                heightL: '',
                pressureL: '',
                wind_speedL: '',
                wind_directionL: '',
                delta_tL: '',
                thermalInversionL: '',
                solarL: '',
                rainL: '',
                selectedTime: ''
            });
        }
    };

    generateChart() {
        const { res } = this.props;
        const { selectedInfo, filterStations } = this.state;
        let modulesLabel = '';
        let labels = filterStations.map((station) => Moment(station.datetime).format("HH:mm"));

        if (selectedInfo === 'weatherChart') {
            const temperatureData = filterStations
                .filter(module => module.air_temperature !== -99)
                .map(module => module.air_temperature?.toFixed(1));

            const filteredTemperatureData = temperatureData.filter(temp => temp !== 0 && temp !== undefined);
            const maxTemperature = Math.max(...filteredTemperatureData);
            const minTemperature = Math.min(...filteredTemperatureData);

            const temperatureDataset = {
                label: res.TEMPERATURA_UNI,
                data: temperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const humidityData = filterStations
                .filter(module => module.air_humidity !== -99)
                .map(module => {
                    let humidity = module.air_humidity?.toFixed(1);
                    return humidity > 100 ? 100 : humidity;
                });

            const filteredHumidityData = humidityData.filter(humidity => humidity !== 0 && humidity !== undefined);
            let maxHumidity, minHumidity;

            if (filteredHumidityData.length > 0) {
                maxHumidity = Math.max(...filteredHumidityData);
                minHumidity = Math.min(...filteredHumidityData);
            } else {
                maxHumidity = minHumidity = 'Sem conexão';
            }

            const humidityDataset = {
                label: res.HUMIDADE_UNI,
                data: humidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            const windSpeedData = filterStations
                .filter(module => module.wind_speed !== -99)
                .map(module => module.wind_speed?.toFixed(1));
            const filteredWindSpeedData = windSpeedData.filter(wind => wind !== 0 && wind !== undefined);
            const maxWindSpeed = Math.max(...filteredWindSpeedData);
            const minWindSpeed = Math.min(...filteredWindSpeedData);

            const windSpeedDataset = {
                label: res.VELOCIDADE_VENTO,
                data: windSpeedData,
                yAxisID: 'windSpeed',
                backgroundColor: '#FFE7D0',
                borderColor: '#FFE7D0',
                borderWidth: 1
            };

            modulesLabel = `
            Temperatura: ${isFinite(minTemperature) && isFinite(maxTemperature)
                    ? `${minTemperature.toFixed(1)}°C / ${maxTemperature.toFixed(1)}°C`
                    : 'Sem conexão'
                }
            Umidade: ${isFinite(minHumidity) && isFinite(maxHumidity)
                    ? `${minHumidity.toFixed(1)} % / ${maxHumidity.toFixed(1)} %`
                    : 'Sem conexão'
                }
            Velocidade do Vento: ${isFinite(minWindSpeed) && isFinite(maxWindSpeed)
                    ? `${minWindSpeed.toFixed(1)} km/h / ${maxWindSpeed.toFixed(1)} km/h`
                    : 'Sem conexão'
                }
          `;

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartModulesData');

            if (chartElement) {
                const ctx = chartElement.getContext('2d');

                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [temperatureDataset, humidityDataset, windSpeedDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            temperature: {
                                id: 'temperature',
                                type: 'linear',
                                title: {
                                    display: true,
                                    color: '#FA9F34',
                                    text: res.TEMPERATURA_UNI,
                                },
                                ticks: {
                                    color: 'lightgray',
                                },
                                display: true,
                                position: 'left',
                                scaleLabel: {
                                    display: true,
                                    labelString: res.TEMPERATURA_UNI,
                                    color: 'white',
                                },
                            },
                            humidity: {
                                id: 'humidity',
                                type: 'linear',
                                title: {
                                    display: true,
                                    color: '#FA3834',
                                    text: res.HUMIDADE_UNI,
                                },
                                ticks: {
                                    color: 'lightgray',
                                },
                                display: true,
                                position: 'right',
                                scaleLabel: {
                                    display: true,
                                    labelString: res.HUMIDADE_UNI,
                                    color: 'white',
                                },
                            },
                            windSpeed: {
                                id: 'windSpeed',
                                type: 'linear',
                                title: {
                                    display: true,
                                    color: '#FFE7D0',
                                    text: res.VELOCIDADE_VENTO,
                                },
                                ticks: {
                                    color: 'lightgray',
                                },
                                display: true,
                                position: 'right',
                                scaleLabel: {
                                    display: true,
                                    labelString: res.VELOCIDADE_VENTO,
                                    color: 'white',
                                },
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'humidity') {
            const humidityData = filterStations
                .filter(module => module.humidity !== -99)
                .map(module => {
                    let humidity = module.humidity?.toFixed(1);
                    return humidity > 100 ? 100 : humidity;
                });

            const filteredHumidityData = humidityData.filter(humidity => humidity !== 0 && humidity !== undefined);
            let maxHumidity, minHumidity;

            if (filteredHumidityData.length > 0) {
                maxHumidity = Math.max(...filteredHumidityData);
                minHumidity = Math.min(...filteredHumidityData);
            } else {
                maxHumidity = minHumidity = 'Sem conexão';
            }

            const humidityDataset = {
                label: 'Umidade Inferior (%)',
                data: humidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const airHumidityData = filterStations
                .filter(module => module.air_humidity !== -99)
                .map(module => {
                    let air_humidity = module.air_humidity?.toFixed(1);
                    return air_humidity > 100 ? 100 : air_humidity;
                });

            const filteredAirHumidityData = airHumidityData.filter(air_humidity => air_humidity !== 0 && air_humidity !== undefined);
            let maxAirHumidity, minAirHumidity;

            if (filteredAirHumidityData.length > 0) {
                maxAirHumidity = Math.max(...filteredAirHumidityData);
                minAirHumidity = Math.min(...filteredAirHumidityData);
            } else {
                maxAirHumidity = minAirHumidity = 'Sem conexão';
            }

            const airHumidityDataset = {
                label: 'Umidade Superior (%)',
                data: airHumidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            modulesLabel = `
            Umidade Inferior: ${isFinite(minHumidity) && isFinite(maxHumidity)
                    ? `${minHumidity.toFixed(1)} % / ${maxHumidity.toFixed(1)} %`
                    : 'Sem conexão'
                }
            Umidade Superior: ${isFinite(minAirHumidity) && isFinite(maxAirHumidity)
                    ? `${minAirHumidity.toFixed(1)} % / ${maxAirHumidity.toFixed(1)} %`
                    : 'Sem conexão'
                }
          `;

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartHumidity');

            if (chartElement) {
                const ctx = chartElement.getContext('2d');

                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [humidityDataset, airHumidityDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            humidity: {
                                id: 'humidity',
                                type: 'linear',
                                title: {
                                    display: true,
                                    color: '#FA9F34',
                                    text: 'Umidade Inferior (%)',
                                },
                                ticks: {
                                    color: 'lightgray',
                                },
                                display: true,
                                position: 'left',
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Umidade Inferior (%)',
                                    color: 'white',
                                },
                            },
                            air_humidity: {
                                id: 'air_humidity',
                                type: 'linear',
                                title: {
                                    display: true,
                                    color: '#FA3834',
                                    text: 'Umidade Superior (%)',
                                },
                                ticks: {
                                    color: 'lightgray',
                                },
                                display: true,
                                position: 'right',
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Umidade Superior (%)',
                                    color: 'white',
                                },
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'temperature') {
            const temperatureData = filterStations
                .filter(module => module.temperature !== -99)
                .map(module => module.temperature?.toFixed(1));
            const filteredTemperatureData = temperatureData.filter(temperature => temperature !== 0 && temperature !== undefined);
            let maxTemperature, minTemperature;

            if (filteredTemperatureData.length > 0) {
                maxTemperature = Math.max(...filteredTemperatureData);
                minTemperature = Math.min(...filteredTemperatureData);
            } else {
                maxTemperature = minTemperature = 'Sem conexão';
            }

            const temperatureDataset = {
                label: 'Temperatura Inferior (°C)',
                data: temperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };
            const airTemperatureData = filterStations
                .filter(module => module.air_temperature !== -99)
                .map(module => module.air_temperature?.toFixed(1));
            const filteredAirTemperatureData = airTemperatureData.filter(air_temperature => air_temperature !== 0 && air_temperature !== undefined);
            let maxAirTemperature, minAirTemperature;

            if (filteredAirTemperatureData.length > 0) {
                maxAirTemperature = Math.max(...filteredAirTemperatureData);
                minAirTemperature = Math.min(...filteredAirTemperatureData);
            } else {
                maxAirTemperature = minAirTemperature = 'Sem conexão';
            }

            const airTemperatureDataset = {
                label: 'Temperatura Superior (°C)',
                data: airTemperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            modulesLabel = `
            Temperatura Inferior: ${isFinite(minTemperature) && isFinite(maxTemperature)
                    ? `${minTemperature.toFixed(1)} % / ${maxTemperature.toFixed(1)} %`
                    : 'Sem conexão'
                }
            Temperatura Superior: ${isFinite(minAirTemperature) && isFinite(maxAirTemperature)
                    ? `${minAirTemperature.toFixed(1)} % / ${maxAirTemperature.toFixed(1)} %`
                    : 'Sem conexão'
                }
          `;

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartTemperature');

            if (chartElement) {
                const ctx = chartElement.getContext('2d');

                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [temperatureDataset, airTemperatureDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            temperature: {
                                id: 'temperature',
                                type: 'linear',
                                title: {
                                    display: true,
                                    color: '#FA9F34',
                                    text: 'Temperatura Inferior (°C)',
                                },
                                ticks: {
                                    color: 'lightgray',
                                },
                                display: true,
                                position: 'left',
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Temperatura Inferior (°C)',
                                    color: 'white',
                                }
                            },
                            air_temperature: {
                                id: 'air_temperature',
                                type: 'linear',
                                title: {
                                    display: true,
                                    color: '#FA3834',
                                    text: 'Temperatura Superior (°C)',
                                },
                                ticks: {
                                    color: 'lightgray',
                                },
                                display: true,
                                position: 'right',
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Temperatura Superior (°C)',
                                    color: 'white',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'thermalInversion') {
            const thermalInversionData = filterStations.map((module) => {
                const thermalInversion1 = module.air_temperature - 1 > module.temperature;
                const thermalInversion5 = module.air_temperature - 5 > module.temperature;

                if (thermalInversion5) {
                    return { value: 3, color: '#FA9F34' };
                } else if (thermalInversion1) {
                    return { value: 2, color: '#FABA34' };
                } else {
                    return { value: 1, color: '#FAB288' };
                }
            });

            const generateLabel = (value) => {
                if (value === 1) {
                    return 'Não está ocorrendo inversão térmica';
                } else if (value === 2) {
                    return 'Pode estar ocorrendo inversão térmica';
                } else if (value === 3) {
                    return 'Grandes chances de estar ocorrendo inversão térmica';
                }
            };

            const thermalInversionDataset = {
                label: 'Inversão térmica',
                data: thermalInversionData.map(data => data.value),
                backgroundColor: thermalInversionData.map(data => data.color),
                borderColor: thermalInversionData.map(data => data.color),
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartThermalInversion');

            if (chartElement) {
                const ctx = chartElement.getContext('2d');

                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [thermalInversionDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                labels: {
                                    color: 'white',
                                    generateLabels: function () {
                                        return [
                                            {
                                                text: 'Não está ocorrendo inversão térmica',
                                                fillStyle: '#FAB288',
                                                lineWidth: 1,
                                            },
                                            {
                                                text: 'Pode estar ocorrendo inversão térmica',
                                                fillStyle: '#FABA34',
                                                lineWidth: 1,
                                            },
                                            {
                                                text: 'Grandes chances de estar ocorrendo inversão térmica',
                                                fillStyle: '#FA9F34',
                                                lineWidth: 1,
                                            }
                                        ];
                                    }
                                }
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const value = context.dataset.data[context.dataIndex];
                                        return generateLabel(value);
                                    }
                                }
                            }
                        },
                        scales: {
                            y: {
                                min: 0,
                                max: 4,
                                ticks: {
                                    color: 'lightgray',
                                    stepSize: 1,
                                    callback: function (val) {
                                        return val === 3 ? 'Grandes chances de inversão' :
                                            val === 2 ? 'Pode estar ocorrendo inversão' :
                                                val === 1 ? 'Não está ocorrendo inversão' : null;
                                    }
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        }
                    }
                });
            }
        } else if (selectedInfo === 'rain') {
            const rainData = filterStations.map((module) => {
                const rainValue = module.rain;
                return rainValue === -99 || rainValue === undefined ? 0 : rainValue;
            });
            const validData = rainData.filter(value => value > 0);
            const maxVal = Math.max(...validData);
            const minVal = Math.min(...validData);
            const formatMinMaxLabel = (min, max) => {
                if (isFinite(min) && isFinite(max)) {
                    return `Min: ${min.toFixed(1)} mm / Max: ${max.toFixed(1)} mm`;
                } else {
                    return `Min/Max: Sem dados válidos`;
                }
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartRain');

            if (chartElement) {
                const ctx = chartElement.getContext('2d');

                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: `${res.CHUVA} (mm)`,
                            data: rainData,
                            backgroundColor: '#FA9F34',
                            borderColor: '#FA9F34',
                            borderWidth: 1,
                            pointBackgroundColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            pointBorderColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            fill: false,
                        }],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    color: 'white',
                                },
                                title: {
                                    display: true,
                                    text: `${res.CHUVA} (mm)`,
                                    color: 'white',
                                },
                            },
                            x: {
                                ticks: {
                                    color: 'white',
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                            tooltip: {
                                mode: 'index',
                                intersect: false,
                            },
                            title: {
                                display: true,
                                text: formatMinMaxLabel(minVal, maxVal),
                                color: 'white',
                                position: 'bottom',
                            },
                        },
                        elements: {
                            line: {
                                tension: 0.1
                            },
                            point: {
                                radius: 3
                            }
                        },
                    },
                });
            }
        } else if (selectedInfo === 'solar') {
            let startTime = '-';
            let endTime = '-';
            let firstSolarValue = false;
            const solarValues = [];

            filterStations.forEach((module) => {
                const solarValue = module.solar;
                const time = Moment(module.datetime).format("HH:mm");
                let color, value;
                if (solarValue === -99 || solarValue < 3) {
                    color = '#FAB288';
                    value = 1;
                } else if (solarValue >= 3 && solarValue <= 400) {
                    if (!firstSolarValue) {
                        startTime = time;
                        firstSolarValue = true;
                    }
                    endTime = time;

                    color = '#FABA34';
                    value = 2;
                } else if (solarValue >= 401 && solarValue <= 800) {
                    color = '#FA9F34';
                    value = 3;
                } else if (solarValue > 801) {
                    color = '#FA3834';
                    value = 4;
                }

                solarValues.push({ value, color });
            });

            const solarDataset = {
                label: `Nível de ${res.RADIACAO_SOLAR}`,
                data: solarValues.map(item => item.value),
                backgroundColor: solarValues.map(item => item.color),
                borderColor: solarValues.map(item => item.color),
                borderWidth: 1
            };
            const formatMinMaxLabel = (startTime, endTime) => {
                if (startTime && endTime) {
                    return `Início da radiação solar: ${startTime} / Término da radiação solar: ${endTime}`;
                } else {
                    return `Sem dados válidos`;
                }
            };
            const generateLabel = (value) => {
                if (value === 1) {
                    return 'Sem radiação solar';
                } else if (value === 2) {
                    return 'Radiação solar baixa';
                } else if (value === 3) {
                    return 'Radiação solar moderada';
                } else if (value === 4) {
                    return 'Radiação solar alta';
                }
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartSolar');

            if (chartElement) {
                const ctx = chartElement.getContext('2d');

                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [solarDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                labels: {
                                    color: 'white',
                                    generateLabels: function () {
                                        return [
                                            {
                                                text: 'Sem radiação solar',
                                                fillStyle: '#FAB288',
                                                lineWidth: 1,
                                            },
                                            {
                                                text: 'Radiação solar baixa',
                                                fillStyle: '#FABA34',
                                                lineWidth: 1,
                                            },
                                            {
                                                text: 'Radiação solar moderada',
                                                fillStyle: '#FA9F34',
                                                lineWidth: 1,
                                            },
                                            {
                                                text: 'Radiação solar alta',
                                                fillStyle: '#FA3834',
                                                lineWidth: 1,
                                            }
                                        ];
                                    }
                                }
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const value = context.dataset.data[context.dataIndex];
                                        return generateLabel(value);
                                    }
                                }
                            },
                            title: {
                                display: true,
                                text: formatMinMaxLabel(startTime, endTime),
                                color: 'white',
                                position: 'bottom',
                            },
                        },
                        scales: {
                            y: {
                                min: 1,
                                max: 5,
                                ticks: {
                                    color: 'lightgray',
                                    stepSize: 1,
                                    callback: function (val, index) {
                                        switch (val) {
                                            case 4:
                                                return 'Radiação solar alta';
                                            case 3:
                                                return 'Radiação solar moderada';
                                            case 2:
                                                return 'Radiação solar baixa';
                                            case 1:
                                                return 'Sem radiação solar';
                                            default:
                                                return null;
                                        }
                                    }
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        }
                    }
                });
            }
        } else {
            const modulesData = filterStations
                .filter(module => module[selectedInfo] !== -99 && module[selectedInfo] !== undefined && module[selectedInfo] !== null)
                .map(module => module[selectedInfo].toFixed(1));

            const filteredModulesData = modulesData.filter(value => value !== 0 && value !== '0.0');
            const maxVal = Math.max(...filteredModulesData);
            const minVal = Math.min(...filteredModulesData);

            const formatLabel = (label, min, max, unit) => {
                if (isFinite(min) && isFinite(max)) {
                    return `${label}: ${min.toFixed(1)} ${unit} / ${max.toFixed(1)} ${unit}`;
                } else {
                    return `${label}: Sem conexão`;
                }
            }

            let unit;
            if (selectedInfo === 'pressure') {
                unit = 'hPa';
                modulesLabel = formatLabel(res.PRESSAO, minVal, maxVal, unit);
            } else if (selectedInfo === 'height') {
                unit = 'm';
                modulesLabel = formatLabel(res.ALTITUDE, minVal, maxVal, unit);
            } else if (selectedInfo === 'wind_direction') {
                unit = '°';
                modulesLabel = formatLabel(res.DIRECAO_VENTO, minVal, maxVal, unit);
            } else if (selectedInfo === 'wind_speed') {
                unit = 'km/h';
                modulesLabel = formatLabel(res.VENTO_VELOCIDADE, minVal, maxVal, unit);
            } else if (selectedInfo === 'delta_t') {
                unit = '';
                modulesLabel = formatLabel(res.DELTA_T, minVal, maxVal, unit);
            }
            let valueLabel = '';
            if (selectedInfo === 'pressure') {
                valueLabel = `${res.PRESSAO} (${res.HECTOPASCAL})`;
            } else if (selectedInfo === 'height') {
                valueLabel = `${res.ALTITUDE} (${res.METROS})`;
            } else if (selectedInfo === 'wind_direction') {
                valueLabel = `${res.DIRECAO_VENTO} (${res.GRAUS})`;
            } else if (selectedInfo === 'wind_speed') {
                valueLabel = `${res.VENTO_VELOCIDADE} (${res.KM_H})`;
            } else if (selectedInfo === 'delta_t') {
                valueLabel = `${res.DELTA_T}`;
            }

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartModules');

            if (chartElement) {
                const ctx = chartElement.getContext('2d');

                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [{
                            label: valueLabel,
                            data: modulesData,
                            backgroundColor: '#FA9F34',
                            borderColor: '#FA9F34',
                            borderWidth: 1,
                            fill: false,
                        }],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                ticks: {
                                    color: 'white',
                                },
                                title: {
                                    display: true,
                                    text: valueLabel,
                                    color: '#FA9F34',
                                },
                            },
                            x: {
                                ticks: {
                                    color: 'white',
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                        },
                    },
                });
            }
        }
    }

    list_stations_data = (stationId, date) => {
        const apiUrl = `${Config.server}/weather_data/${stationId}/${date}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        const sortedByDateDesc = [...result].sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
                        const sortedByDateAsc = [...result].sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
                        const mostRecentStation = sortedByDateDesc[0];
                        const lastDataTime = Moment(mostRecentStation.datetime);
                        const currentTime = Moment();

                        let addressExist = false;

                        if (mostRecentStation.addressStation !== undefined) {
                            if (mostRecentStation.addressStation === 255) {
                                addressExist = true;
                            }
                        }

                        this.setState({ filterStations: sortedByDateAsc, sliderData: result, addressExists: addressExist, noData: false, isLoading: false });
                        this.generateChart();
                    } else if (result.length === 0) {
                        this.setState({ noData: true });
                        notification.open({
                            message: this.props.res.ATENCAO,
                            description: this.props.res.ERRO_ESTACAO,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    updateModalField = (modulesName, value) => {
        this.setState({ [modulesName]: value });
    };

    getDataById = (sourceID, stationName) => {
        const apiUrl = Config.server + `/weather_data/${sourceID}`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then((result) => {
                if (result.length > 0) {
                    const { stationData, idStationSelected } = this.state;

                    const element = result[result.length - 1];
                    const tempMark = new WeatherData();
                    tempMark.name = stationName;
                    tempMark.id = element.id;
                    tempMark.id_company = element.id_company;
                    tempMark.id_source = element.id_source;
                    tempMark.lat = element.latAverage;
                    tempMark.lng = element.lngAverage;
                    tempMark.height = element.heightAverage;
                    tempMark.datetime = element.datetime;
                    tempMark.pressure = element.pressure;
                    tempMark.humidity = element.humidity;
                    tempMark.air_humidity = element.air_humidity;
                    tempMark.temperature = element.temperature;
                    tempMark.air_temperature = element.air_temperature;
                    tempMark.wind_direction = element.wind_direction;
                    tempMark.wind_speed = element.wind_speed;
                    tempMark.delta_t = element.delta_t;
                    tempMark.data_plus = element.data_plus;
                    tempMark.rain = element.rain;
                    tempMark.solar = element.solar;
                    tempMark.addressStation = element.addressStation;

                    let iconStation;
                    const lastDataTime = Moment(tempMark.datetime).format('DD/MM/YY HH:mm');
                    const currentTime = Moment().format('DD/MM/YY HH:mm');
                    const diffInMinutes = Moment(currentTime, 'DD/MM/YY HH:mm').diff(Moment(lastDataTime, 'DD/MM/YY HH:mm'), 'minutes');

                    if (diffInMinutes >= 0 && diffInMinutes <= 20) {
                        iconStation = 22;
                    } else {
                        iconStation = 23;
                    }

                    tempMark.img = img[Number(iconStation)].img;

                    this.drawMarker(tempMark);
                    const existingDataIndex = stationData.findIndex(sd => sd.id_source === tempMark.id_source);
                    if (existingDataIndex !== -1) {
                        stationData[existingDataIndex] = tempMark;
                    } else {
                        stationData.push(tempMark);
                    }
                    if (idStationSelected === sourceID) {
                        const datetimeData = result.map((item) => Moment(item.datetime).format('HH').replace(/^0+/, ''));
                        this.setState({ selectedTime: datetimeData[0] });

                        const item = tempMark;
                        const deltaT = item.delta_t?.toFixed(1);
                        const deltaTValue = deltaT != -99 && deltaT !== undefined && deltaT !== null ? deltaT : 'Sem conexão';

                        if (deltaTValue === false) {
                            this.setState({ flightConditions: `Sem conexão` });
                        } else if (deltaTValue >= 2 && deltaTValue <= 8) {
                            this.setState({ flightConditions: `Condições adequadas` });
                        } else if (deltaTValue > 10) {
                            this.setState({ flightConditions: `Condições inadequadas` });
                        } else {
                            this.setState({ flightConditions: `Condições arriscadas` });
                        }

                        this.updateModalField('latL', { error: false, input: geo.toDMS(item.lat, 'latitude', 1), value: item.lat });
                        this.updateModalField('lngL', { error: false, input: geo.toDMS(item.lng, 'longitude', 1), value: item.lng });
                        this.updateModalField('nameL', item.name !== -99 && item.name != null ? item.name : 'Sem conexão');
                        this.updateModalField('heightL', item.height !== -99 && item.height != null ? item.height.toFixed(1) : 'Sem conexão');
                        this.updateModalField('datetimeL', item.datetime !== -99 && item.datetime != null ? Moment(item.datetime).format("DD/MM/YY HH:mm") : 'Sem conexão');
                        this.updateModalField('humidityL', item.humidity !== -99 && item.humidity != null ? (item.humidity > 100 ? 100 : item.humidity.toFixed(1)) : 'Sem conexão');
                        this.updateModalField('air_humidityL', item.air_humidity !== -99 && item.air_humidity != null ? (item.air_humidity > 100 ? 100 : item.air_humidity.toFixed(1)) : 'Sem conexão');
                        this.updateModalField('temperatureL', item.temperature !== -99 && item.temperature != null ? item.temperature.toFixed(1) : 'Sem conexão');
                        this.updateModalField('air_temperatureL', item.air_temperature !== -99 && item.air_temperature != null ? item.air_temperature.toFixed(1) : 'Sem conexão');
                        this.updateModalField('wind_directionL', item.wind_direction !== -99 && item.wind_direction != null ? item.wind_direction.toFixed(1) : 'Sem conexão');
                        this.updateModalField('pressureL', item.pressure !== -99 && item.pressure != null ? item.pressure.toFixed(1) : 'Sem conexão');
                        this.updateModalField('wind_speedL', item.wind_speed !== -99 && item.wind_speed != null ? item.wind_speed.toFixed(1) : 'Sem conexão');
                        this.updateModalField('delta_tL', deltaTValue);
                        this.updateModalField('thermalInversionL', Number(element.air_temperature) - 5 > Number(element.temperature) ? 'Grandes chances de estar ocorrendo inversão térmica' : Number(element.air_temperature) - 1 > Number(element.temperature) ? 'Pode estar ocorrendo inversão térmica' : 'Não está ocorrendo inversão térmica',);
                        // this.updateModalField('thermalInversionL', data?.Generic_Weather_station_data?.inversao_termica == true ? 'Pode estar ocorrendo inversão térmica' : 'Não está ocorrendo inversão térmica');
                        // this.updateModalField('addressStationL', data?.Generic_Weather_station_data?.address);
                        // this.updateModalField('rainL', data?.Generic_Weather_station_data?.rain);
                        // this.updateModalField('solarL', data?.Generic_Weather_station_data?.solar);
                        this.updateModalField('addressStationL', item.addressStation !== -99 && item.addressStation != null ? item.addressStation : 'Sem conexão');
                        this.updateModalField('rainL', item.rain !== -99 && item.rain != null ? item.rain : 'Sem conexão');
                        this.updateModalField('solarL', item.solar !== -99 && item.solar != null ? item.solar : 'Sem conexão');
                    }
                    this.setState({ stationData });

                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 1000);
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                if (error.status === 405) {
                    notification.open({
                        message: this.props.res.PERMISSAO,
                        description: this.props.res.PERMISSAO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                } else if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                } else {
                    notification.open({
                        message: this.props.res.ERRO,
                        description: this.props.res.ERRO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                }
            });
    };

    showChart = () => {
        const { selectedInfo, filterStations, noData, monthlyData, yearlyData, weeklyData, isLoading } = this.state;

        if (isLoading) {
            return (
                <Skeleton
                    variant="text"
                    style={{ margin: '0 0.5rem', transform: 'scale(1, 0.75)' }}
                    height={256}
                />
            );
        }

        if (noData === true) {
            return (
                <h3 className="noModulesTitle" >{this.props.res.ERRO_ESTACAO}</h3>
            );
        } else {
            if (filterStations.length > 0) {
                if (selectedInfo.length === 0) {
                    return (
                        <h3 className="noModulesTitle" >{this.props.res.SELECIONAR_MODULO}</h3>
                    );
                } else {
                    if (selectedInfo === 'weatherChart') {
                        return (
                            <canvas style={{ height: '240px' }} id="chartModulesData" ref={this.chartRef}></canvas>
                        );
                    } else if (selectedInfo === 'humidity') {
                        return (
                            <canvas style={{ height: '240px' }} id="chartHumidity" ref={this.chartRef}></canvas>
                        );
                    } else if (selectedInfo === 'temperature') {
                        return (
                            <canvas style={{ height: '240px' }} id="chartTemperature" ref={this.chartRef}></canvas>
                        );
                    } else if (selectedInfo === 'thermalInversion') {
                        return (
                            <canvas style={{ height: '240px' }} id="chartThermalInversion" ref={this.chartRef}></canvas>
                        );
                    } else if (selectedInfo === 'rain') {
                        return (
                            <canvas style={{ height: '240px' }} id="chartRain" ref={this.chartRef}></canvas>
                        );
                    } else if (selectedInfo === 'solar') {
                        return (
                            <canvas style={{ height: '240px' }} id="chartSolar" ref={this.chartRef}></canvas>
                        );
                    } else {
                        return (
                            <canvas style={{ height: '240px' }} id="chartModules" ref={this.chartRef}></canvas>
                        );
                    }
                }
            } else if (monthlyData.length > 0) {
                if (selectedInfo === 'temperatureMonth') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartTemperatureMonth" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'humidityMonth') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartHumidityMonth" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'pressureMonth') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartPressureMonth" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'windSpeedMonth') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartWindSpeedMonth" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'rainMonth') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartRainMonth" ref={this.chartRef}></canvas>
                    );
                }
            } else if (yearlyData.length > 0) {
                if (selectedInfo === 'temperatureYear') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartTemperatureYear" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'humidityYear') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartHumidityYear" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'pressureYear') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartPressureYear" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'windSpeedYear') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartWindSpeedYear" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'rainYear') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartRainYear" ref={this.chartRef}></canvas>
                    );
                }
            } else if (weeklyData.length > 0) {
                if (selectedInfo === 'temperatureWeek') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartTemperatureWeek" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'humidityWeek') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartHumidityWeek" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'pressureWeek') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartPressureWeek" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'windSpeedWeek') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartWindSpeedWeek" ref={this.chartRef}></canvas>
                    );
                } else if (selectedInfo === 'rainWeek') {
                    return (
                        <canvas style={{ height: '240px' }} id="chartRainWeek" ref={this.chartRef}></canvas>
                    );
                }
            }
        }
    };

    clearFilters = () => {
        const today = new Date();
        const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
        const currentYear = today.getFullYear();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);

        this.setState({
            selectedDate: this.getLocalDate(),
            selectedMonth: `${currentYear}-${currentMonth}`,
            selectedYear: currentYear.toString(),
            selectedWeekStart: sevenDaysAgo.toISOString().split('T')[0],
            selectedWeekEnd: today.toISOString().split('T')[0],
            filterStations: [],
            filterStationData: [],
            selectedInfo: '',
        });
    };

    weatherForecast = () => {
        const { longitudeForecast, latitudeForecast } = this.state;

        // LINK API GRATUITA PREVISÃO DO  TEMPO
        // https://openweathermap.org

        const apiToken = 'af8af1343d4086f5a2132b0185e347c7';

        const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${latitudeForecast.value}&lon=${longitudeForecast.value}&appid=${apiToken}&units=metric&lang=pt_br`;

        const options = {
            method: 'GET',
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result) {
                        const daysData = [];
                        result.list.forEach((item) => {
                            const date = Moment(item.dt_txt).format("YYYY-MM-DD");
                            const existingDay = daysData.find((day) => day.date === date);
                            if (!existingDay) {
                                daysData.push({
                                    id: Moment(item.dt_txt).format("D"),
                                    date: date,
                                    minTemp: item.main.temp,
                                    maxTemp: item.main.temp,
                                    climateConditions: item.weather[0].main,
                                    description: item.weather[0].description,
                                    weatherData: [item],
                                });
                            } else {
                                if (item.main.temp < existingDay.minTemp) {
                                    existingDay.minTemp = item.main.temp;
                                }
                                if (item.main.temp > existingDay.maxTemp) {
                                    existingDay.maxTemp = item.main.temp;
                                }
                                existingDay.weatherData.push(item);
                            }
                        });

                        this.setState({
                            weatherForecastData: daysData,
                            consultForecastData: false,
                            weatherForecastCoordinate: result.city
                        });
                        if (this.drawCreateToolbar) {
                            this.drawCreateToolbar.disable();
                        }
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    toggleHoverForecast = (value) => {
        this.setState({
            hoverForecast: value,
        });
    };

    handleTabChange = (event, newValue) => {
        this.setState({ selectedInfo: newValue });
    }

    handleTabHistoricChange = (event, newValue) => {
        this.setState({ selectedHistoricType: newValue });
    };

    toggleMapSize = () => {
        this.setState(prevState => ({
            isMapExpanded: !prevState.isMapExpanded
        }), () => {
            this.mymap.invalidateSize();
        });
    };

    handleCardClick = (hour) => {
        const { sliderData, selectedDate } = this.state;

        const sliderHourData = sliderData.filter(value => {
            const formattedHour = Moment(value.datetime).format("HH");
            return formattedHour === String(hour).padStart(2, '0');
        });

        const selectedDateFormatted = Moment(selectedDate).format("DD/MM/YY");
        const selectedHourFormatted = `${selectedDateFormatted} ${String(hour).padStart(2, '0')}:00`;

        const averages = sliderHourData.reduce((acc, obj) => {
            acc.air_humidity += obj.air_humidity;
            acc.humidity += obj.humidity;
            acc.air_temperature += obj.air_temperature;
            acc.temperature += obj.temperature;
            acc.height += obj.height;
            acc.pressure += obj.pressure;
            acc.wind_speed += obj.wind_speed;
            acc.wind_direction += obj.wind_direction;
            acc.delta_t += obj.delta_t;
            acc.rain += obj.rain;
            acc.solar += obj.solar;

            if (Number(obj.air_temperature) - 5 > Number(obj.temperature)) {
                acc.thermalInversionHighCount++;
            } else if (Number(obj.air_temperature) - 1 > Number(obj.temperature)) {
                acc.thermalInversionCount++;
            }

            if (obj.delta_t !== undefined && obj.delta_t !== null) {
                acc.sumDeltaT += obj.delta_t;
                acc.countDeltaT++;
            }

            if (obj.solar !== undefined && obj.solar !== null) {
                acc.sumSolar += obj.solar;
            }

            if (obj.rain > 0) {
                acc.countRain++;
            }

            return acc;
        }, {
            air_humidity: 0,
            humidity: 0,
            air_temperature: 0,
            temperature: 0,
            height: 0,
            pressure: 0,
            wind_speed: 0,
            wind_direction: 0,
            sumDeltaT: 0,
            countDeltaT: 0,
            thermalInversionCount: 0,
            thermalInversionHighCount: 0,
            sumSolar: 0,
            countRain: 0
        });

        const count = sliderHourData.length;
        const average = {
            air_humidity: averages.air_humidity / count,
            humidity: averages.humidity / count,
            air_temperature: averages.air_temperature / count,
            temperature: averages.temperature / count,
            height: averages.height / count,
            pressure: averages.pressure / count,
            wind_speed: averages.wind_speed / count,
            wind_direction: averages.wind_direction / count,
            averageDeltaT: averages.countDeltaT > 0 ? averages.sumDeltaT / averages.countDeltaT : 0,
            averageSolar: averages.sumSolar / count,
            averageRain: averages.countRain > 0 ? 1 : 0,
            averageThermalInversion: averages.thermalInversionHighCount > 0 ? "Grandes chances de estar ocorrendo inversão térmica" :
                averages.thermalInversionCount > 0 ? "Pode estar ocorrendo inversão térmica" :
                    "Não está ocorrendo inversão térmica"
        };

        if (average.averageDeltaT.toFixed(1) >= 2 && average.averageDeltaT.toFixed(1) <= 8) {
            this.setState({ flightConditions: `Condições adequadas` });
        } else if (average.averageDeltaT.toFixed(1) > 10) {
            this.setState({ flightConditions: `Condições inadequadas` });
        } else {
            this.setState({ flightConditions: `Condições arriscadas` });
        }

        this.setState({
            datetimeL: selectedHourFormatted,
            humidityL: Math.round(average.humidity),
            air_humidityL: Math.round(average.air_humidity),
            temperatureL: Math.round(average.temperature),
            air_temperatureL: Math.round(average.air_temperature),
            heightL: Math.round(average.height),
            pressureL: Math.round(average.pressure),
            wind_speedL: Math.round(average.wind_speed),
            wind_directionL: Math.round(average.wind_direction),
            delta_tL: average.averageDeltaT.toFixed(1),
            thermalInversionL: average.averageThermalInversion,
            solarL: average.averageSolar.toFixed(1),
            rainL: average.averageRain,
            autoUpdate: false,
            selectedTime: hour
        });
        this.stopAutoUpdate();
    };

    readWeatherDataByMonth = () => {
        const { idStationSelected, selectedMonth } = this.state;
        const apiUrl = `${Config.server}/weather_data/monthly/${idStationSelected}/${selectedMonth}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        this.setState(
                            { monthlyData: result, noData: false, selectedInfo: 'temperatureMonth', isLoading: false },
                            () => {
                                this.generateMonthlyChart();
                            }
                        );
                    } else if (result.length === 0) {
                        this.setState({ noData: true })
                        notification.open({
                            message: this.props.res.ATENCAO,
                            description: this.props.res.ERRO_ESTACAO,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    readWeatherDataByYear = () => {
        const { idStationSelected, selectedYear } = this.state;
        const apiUrl = `${Config.server}/weather_data/yearly/${idStationSelected}/${selectedYear}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        this.setState(
                            { yearlyData: result, noData: false, selectedInfo: 'temperatureYear', isLoading: false },
                            () => {
                                this.generateYearlyChart();
                            }
                        );
                    } else if (result.length === 0) {
                        this.setState({ noData: true })
                        notification.open({
                            message: this.props.res.ATENCAO,
                            description: this.props.res.ERRO_ESTACAO,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    readWeatherDataByWeek = () => {
        const { idStationSelected, selectedWeekStart } = this.state;
        const apiUrl = `${Config.server}/weather_data/weekly/${idStationSelected}/${selectedWeekStart}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        this.setState(
                            { weeklyData: result, noData: false, selectedInfo: 'temperatureWeek', isLoading: false },
                            () => {
                                this.generateWeeklyChart();
                            }
                        );
                    } else if (result.length === 0) {
                        this.setState({ noData: true })
                        notification.open({
                            message: this.props.res.ATENCAO,
                            description: this.props.res.ERRO_ESTACAO,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    generateMonthlyChart() {
        const { res } = this.props;
        const { selectedInfo, monthlyData } = this.state;
        let modulesLabel = '';
        let labels;
        if (selectedInfo === 'temperatureMonth') {
            labels = monthlyData.map(module => `${module.day}`);

            const minTemperatureData = monthlyData
                .filter(module => module.min_temperature !== -99 && module.min_temperature != null)
                .map(module => parseFloat(module.min_temperature.toFixed(1)));

            const maxTemperatureData = monthlyData
                .filter(module => module.max_temperature !== -99 && module.max_temperature != null)
                .map(module => parseFloat(module.max_temperature.toFixed(1)));

            const minTempIndex = minTemperatureData.findIndex(temp => temp === Math.min(...minTemperatureData));
            const maxTempIndex = maxTemperatureData.findIndex(temp => temp === Math.max(...maxTemperatureData));

            const minTempDay = labels[minTempIndex];
            const maxTempDay = labels[maxTempIndex];

            modulesLabel = `
                    Dia ${minTempDay}, temperatura mínima: ${minTemperatureData[minTempIndex]} °C
                    Dia ${maxTempDay}, temperatura máxima: ${maxTemperatureData[maxTempIndex]} °C
                `;


            const minTemperatureDataset = {
                label: 'Temperatura Mínima (°C)',
                data: minTemperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxTemperatureDataset = {
                label: 'Temperatura Máxima (°C)',
                data: maxTemperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartTemperatureMonth');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minTemperatureDataset, maxTemperatureDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            temperature: {
                                id: 'temperature',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.TEMPERATURA_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'humidityMonth') {
            labels = monthlyData.map(module => `${module.day}`);

            const minHumidityData = monthlyData
                .filter(module => module.min_humidity !== -99 && module.min_humidity != null)
                .map(module => parseFloat(module.min_humidity.toFixed(1)));

            const maxHumidityData = monthlyData
                .filter(module => module.max_humidity !== -99 && module.max_humidity != null)
                .map(module => parseFloat(module.max_humidity.toFixed(1)));

            const minTempIndex = minHumidityData.findIndex(temp => temp === Math.min(...minHumidityData));
            const maxTempIndex = maxHumidityData.findIndex(temp => temp === Math.max(...maxHumidityData));

            const minHumDay = labels[minTempIndex];
            const maxHumDay = labels[maxTempIndex];

            modulesLabel = `
                    Dia ${minHumDay}, umidade mínima: ${minHumidityData[minTempIndex]} %
                    Dia ${maxHumDay}, umidade máxima: ${maxHumidityData[maxTempIndex]} %
                `;


            const minHumidityDataset = {
                label: 'Umidade Mínima (%)',
                data: minHumidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxHumidityDataset = {
                label: 'Umidade Máxima (%)',
                data: maxHumidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartHumidityMonth');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minHumidityDataset, maxHumidityDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            humidity: {
                                id: 'humidity',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.HUMIDADE_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'pressureMonth') {
            labels = monthlyData.map(module => `${module.day}`);

            const minPressureData = monthlyData
                .filter(module => module.min_pressure !== -99 && module.min_pressure != null)
                .map(module => parseFloat(module.min_pressure.toFixed(1)));

            const maxPressureData = monthlyData
                .filter(module => module.max_pressure !== -99 && module.max_pressure != null)
                .map(module => parseFloat(module.max_pressure.toFixed(1)));

            const minPressureIndex = minPressureData.findIndex(temp => temp === Math.min(...minPressureData));
            const maxPressureIndex = maxPressureData.findIndex(temp => temp === Math.max(...maxPressureData));

            const minPressureDay = labels[minPressureIndex];
            const maxPressureDay = labels[maxPressureIndex];

            modulesLabel = `
                    Dia ${minPressureDay}, pressão mínima: ${minPressureData[minPressureIndex]} hPa
                    Dia ${maxPressureDay}, pressão máxima: ${maxPressureData[maxPressureIndex]} hPa
                `;


            const minPressureDataset = {
                label: 'Pressão Mínima (hPa)',
                data: minPressureData,
                yAxisID: 'pressure',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxPressureDataset = {
                label: 'Pressão Máxima (hPa)',
                data: maxPressureData,
                yAxisID: 'pressure',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartPressureMonth');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minPressureDataset, maxPressureDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            pressure: {
                                id: 'pressure',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.PRESSAO_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'windSpeedMonth') {
            labels = monthlyData.map(module => `${module.day}`);

            const minWindSpeedData = monthlyData
                .filter(module => module.min_wind_speed !== -99 && module.min_wind_speed != null)
                .map(module => parseFloat(module.min_wind_speed.toFixed(1)));

            const maxWindSpeedData = monthlyData
                .filter(module => module.max_wind_speed !== -99 && module.max_wind_speed != null)
                .map(module => parseFloat(module.max_wind_speed.toFixed(1)));

            const minWindSpeedIndex = minWindSpeedData.findIndex(temp => temp === Math.min(...minWindSpeedData));
            const maxWindSpeedIndex = maxWindSpeedData.findIndex(temp => temp === Math.max(...maxWindSpeedData));

            const minWindSpeedDay = labels[minWindSpeedIndex];
            const maxWindSpeedDay = labels[maxWindSpeedIndex];

            modulesLabel = `
                Dia ${minWindSpeedDay || '-'}, vel. do vento mínima: ${minWindSpeedData[minWindSpeedIndex] !== undefined ? minWindSpeedData[minWindSpeedIndex] + ' km/h' : '-'}
                Dia ${maxWindSpeedDay || '-'}, vel. do vento máxima: ${maxWindSpeedData[maxWindSpeedIndex] !== undefined ? maxWindSpeedData[maxWindSpeedIndex] + ' km/h' : '-'}
            `;

            const minWindSpeedDataset = {
                label: 'Velocidade do Vento Mínima (km/h)',
                data: minWindSpeedData,
                yAxisID: 'windSpeed',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxWindSpeedDataset = {
                label: 'Velocidade do Vento Máxima (km/h)',
                data: maxWindSpeedData,
                yAxisID: 'windSpeed',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartWindSpeedMonth');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minWindSpeedDataset, maxWindSpeedDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            windSpeed: {
                                id: 'windSpeed',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.VELOCIDADE_VENTO,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'rainMonth') {
            labels = monthlyData.map(module => `${module.day}`);

            const rainData = monthlyData
                .map(module => module.total_rain !== undefined && module.total_rain !== -99 && module.total_rain != null && module.total_rain >= 0
                    ? parseFloat(module.total_rain.toFixed(1))
                    : 0);

            const totalRainfall = rainData.reduce((acc, curr) => acc + curr, 0);

            modulesLabel = `Total de chuva no mês: ${totalRainfall.toFixed(1)} mm`;

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartRainMonth');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: 'Chuva diária (mm)',
                            data: rainData,
                            backgroundColor: '#FA9F34',
                            borderColor: '#FA9F34',
                            borderWidth: 1,
                            pointBackgroundColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            pointBorderColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            fill: false,
                        }],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    color: 'white',
                                },
                                title: {
                                    display: true,
                                    text: `${res.CHUVA} (mm)`,
                                    color: 'white',
                                },
                            },
                            x: {
                                ticks: {
                                    color: 'white',
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                            tooltip: {
                                mode: 'index',
                                intersect: false,
                            },
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                        },
                        elements: {
                            line: {
                                tension: 0.1
                            },
                            point: {
                                radius: 3
                            }
                        },
                    },
                });
            }
        }
    }

    generateYearlyChart() {
        const { res } = this.props;
        const { selectedInfo, yearlyData } = this.state;
        let modulesLabel = '';
        let labels;

        const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

        if (selectedInfo === 'temperatureYear') {
            labels = yearlyData.map(module => `${monthNames[module.month - 1]}`);

            const minTemperatureData = yearlyData
                .filter(module => module.min_temperature !== -99 && module.min_temperature != null)
                .map(module => parseFloat(module.min_temperature.toFixed(1)));

            const maxTemperatureData = yearlyData
                .filter(module => module.max_temperature !== -99 && module.max_temperature != null)
                .map(module => parseFloat(module.max_temperature.toFixed(1)));

            const minTempIndex = minTemperatureData.findIndex(temp => temp === Math.min(...minTemperatureData));
            const maxTempIndex = maxTemperatureData.findIndex(temp => temp === Math.max(...maxTemperatureData));

            const minTempMonth = labels[minTempIndex];
            const maxTempMonth = labels[maxTempIndex];

            modulesLabel = `
                Mês ${minTempMonth}, temperatura mínima: ${minTemperatureData[minTempIndex]} °C
                Mês ${maxTempMonth}, temperatura máxima: ${maxTemperatureData[maxTempIndex]} °C
            `;

            const minTemperatureDataset = {
                label: 'Temperatura Mínima (°C)',
                data: minTemperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxTemperatureDataset = {
                label: 'Temperatura Máxima (°C)',
                data: maxTemperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartTemperatureYear');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minTemperatureDataset, maxTemperatureDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            temperature: {
                                id: 'temperature',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.TEMPERATURA_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'humidityYear') {
            labels = yearlyData.map(module => `${monthNames[module.month - 1]}`);

            const minHumidityData = yearlyData
                .filter(module => module.min_humidity !== -99 && module.min_humidity != null)
                .map(module => parseFloat(module.min_humidity.toFixed(1)));

            const maxHumidityData = yearlyData
                .filter(module => module.max_humidity !== -99 && module.max_humidity != null)
                .map(module => parseFloat(module.max_humidity.toFixed(1)));

            const minHumIndex = minHumidityData.findIndex(hum => hum === Math.min(...minHumidityData));
            const maxHumIndex = maxHumidityData.findIndex(hum => hum === Math.max(...maxHumidityData));

            const minHumMonth = labels[minHumIndex];
            const maxHumMonth = labels[maxHumIndex];

            modulesLabel = `
                Mês ${minHumMonth}, umidade mínima: ${minHumidityData[minHumIndex]} %
                Mês ${maxHumMonth}, umidade máxima: ${maxHumidityData[maxHumIndex]} %
            `;

            const minHumidityDataset = {
                label: 'Umidade Mínima (%)',
                data: minHumidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxHumidityDataset = {
                label: 'Umidade Máxima (%)',
                data: maxHumidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartHumidityYear');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minHumidityDataset, maxHumidityDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            humidity: {
                                id: 'humidity',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.HUMIDADE_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'pressureYear') {
            labels = yearlyData.map(module => `${monthNames[module.month - 1]}`);

            const minPressureData = yearlyData
                .filter(module => module.min_pressure !== -99 && module.min_pressure != null)
                .map(module => parseFloat(module.min_pressure.toFixed(1)));

            const maxPressureData = yearlyData
                .filter(module => module.max_pressure !== -99 && module.max_pressure != null)
                .map(module => parseFloat(module.max_pressure.toFixed(1)));

            const minPressureIndex = minPressureData.findIndex(pressure => pressure === Math.min(...minPressureData));
            const maxPressureIndex = maxPressureData.findIndex(pressure => pressure === Math.max(...maxPressureData));

            const minPressureMonth = labels[minPressureIndex];
            const maxPressureMonth = labels[maxPressureIndex];

            modulesLabel = `
                Mês ${minPressureMonth}, pressão mínima: ${minPressureData[minPressureIndex]} hPa
                Mês ${maxPressureMonth}, pressão máxima: ${maxPressureData[maxPressureIndex]} hPa
            `;

            const minPressureDataset = {
                label: 'Pressão Mínima (hPa)',
                data: minPressureData,
                yAxisID: 'pressure',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxPressureDataset = {
                label: 'Pressão Máxima (hPa)',
                data: maxPressureData,
                yAxisID: 'pressure',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartPressureYear');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minPressureDataset, maxPressureDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            pressure: {
                                id: 'pressure',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.PRESSAO_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'windSpeedYear') {
            labels = yearlyData.map(module => `${monthNames[module.month - 1]}`);

            const minWindSpeedData = yearlyData
                .filter(module => module.min_wind_speed !== -99 && module.min_wind_speed != null)
                .map(module => parseFloat(module.min_wind_speed.toFixed(1)));

            const maxWindSpeedData = yearlyData
                .filter(module => module.max_wind_speed !== -99 && module.max_wind_speed != null)
                .map(module => parseFloat(module.max_wind_speed.toFixed(1)));

            const minWindSpeedIndex = minWindSpeedData.findIndex(speed => speed === Math.min(...minWindSpeedData));
            const maxWindSpeedIndex = maxWindSpeedData.findIndex(speed => speed === Math.max(...maxWindSpeedData));

            const minWindSpeedMonth = labels[minWindSpeedIndex];
            const maxWindSpeedMonth = labels[maxWindSpeedIndex];

            modulesLabel = `
                Mês ${minWindSpeedMonth || '-'}, vel. do vento mínima: ${minWindSpeedData[minWindSpeedIndex] !== undefined ? minWindSpeedData[minWindSpeedIndex] + ' km/h' : '-'}
                Mês ${maxWindSpeedMonth || '-'}, vel. do vento máxima: ${maxWindSpeedData[maxWindSpeedIndex] !== undefined ? maxWindSpeedData[maxWindSpeedIndex] + ' km/h' : '-'}
            `;

            const minWindSpeedDataset = {
                label: 'Velocidade do Vento Mínima (km/h)',
                data: minWindSpeedData,
                yAxisID: 'windSpeed',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxWindSpeedDataset = {
                label: 'Velocidade do Vento Máxima (km/h)',
                data: maxWindSpeedData,
                yAxisID: 'windSpeed',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartWindSpeedYear');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minWindSpeedDataset, maxWindSpeedDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            windSpeed: {
                                id: 'windSpeed',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.VELOCIDADE_VENTO,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'rainYear') {
            labels = yearlyData.map(module => `${monthNames[module.month - 1]}`);

            const rainData = yearlyData
                .map(module => module.total_rain !== undefined && module.total_rain !== -99 && module.total_rain != null && module.total_rain >= 0
                    ? parseFloat(module.total_rain.toFixed(1))
                    : 0);

            const totalRainfall = rainData.reduce((acc, curr) => acc + curr, 0);

            modulesLabel = `Total de chuva no ano: ${totalRainfall.toFixed(1)} mm`;

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartRainYear');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: 'Chuva mensal (mm)',
                            data: rainData,
                            backgroundColor: '#FA9F34',
                            borderColor: '#FA9F34',
                            borderWidth: 1,
                            pointBackgroundColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            pointBorderColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            fill: false,
                        }],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    color: 'white',
                                },
                                title: {
                                    display: true,
                                    text: `${res.CHUVA} (mm)`,
                                    color: 'white',
                                },
                            },
                            x: {
                                ticks: {
                                    color: 'white',
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                            tooltip: {
                                mode: 'index',
                                intersect: false,
                            },
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                        },
                        elements: {
                            line: {
                                tension: 0.1
                            },
                            point: {
                                radius: 3
                            }
                        },
                    },
                });
            }
        }
    }

    generateWeeklyChart() {
        const { res } = this.props;
        const { selectedInfo, weeklyData } = this.state;
        let modulesLabel = '';
        let labels;
        const options = { month: '2-digit', day: '2-digit' };

        if (selectedInfo === 'temperatureWeek') {
            labels = weeklyData.map(module => new Date(module.date).toLocaleDateString('pt-BR', options));

            const minTemperatureData = weeklyData
                .filter(module => module.min_temperature !== -99 && module.min_temperature != null)
                .map(module => parseFloat(module.min_temperature.toFixed(1)));

            const maxTemperatureData = weeklyData
                .filter(module => module.max_temperature !== -99 && module.max_temperature != null)
                .map(module => parseFloat(module.max_temperature.toFixed(1)));

            const minTempIndex = minTemperatureData.findIndex(temp => temp === Math.min(...minTemperatureData));
            const maxTempIndex = maxTemperatureData.findIndex(temp => temp === Math.max(...maxTemperatureData));

            const minTempDay = labels[minTempIndex];
            const maxTempDay = labels[maxTempIndex];

            modulesLabel = `
                    Dia ${minTempDay}, temperatura mínima: ${minTemperatureData[minTempIndex]} °C
                    Dia ${maxTempDay}, temperatura máxima: ${maxTemperatureData[maxTempIndex]} °C
                `;

            const minTemperatureDataset = {
                label: 'Temperatura Mínima (°C)',
                data: minTemperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxTemperatureDataset = {
                label: 'Temperatura Máxima (°C)',
                data: maxTemperatureData,
                yAxisID: 'temperature',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartTemperatureWeek');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minTemperatureDataset, maxTemperatureDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            temperature: {
                                id: 'temperature',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.TEMPERATURA_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'humidityWeek') {
            labels = weeklyData.map(module => new Date(module.date).toLocaleDateString('pt-BR', options));

            const minHumidityData = weeklyData
                .filter(module => module.min_humidity !== -99 && module.min_humidity != null)
                .map(module => parseFloat(module.min_humidity.toFixed(1)));

            const maxHumidityData = weeklyData
                .filter(module => module.max_humidity !== -99 && module.max_humidity != null)
                .map(module => parseFloat(module.max_humidity.toFixed(1)));

            const minHumIndex = minHumidityData.findIndex(hum => hum === Math.min(...minHumidityData));
            const maxHumIndex = maxHumidityData.findIndex(hum => hum === Math.max(...maxHumidityData));

            const minHumDay = labels[minHumIndex];
            const maxHumDay = labels[maxHumIndex];

            modulesLabel = `
                    Dia ${minHumDay}, umidade mínima: ${minHumidityData[minHumIndex]} %
                    Dia ${maxHumDay}, umidade máxima: ${maxHumidityData[maxHumIndex]} %
                `;

            const minHumidityDataset = {
                label: 'Umidade Mínima (%)',
                data: minHumidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxHumidityDataset = {
                label: 'Umidade Máxima (%)',
                data: maxHumidityData,
                yAxisID: 'humidity',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartHumidityWeek');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minHumidityDataset, maxHumidityDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            humidity: {
                                id: 'humidity',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.HUMIDADE_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'pressureWeek') {
            labels = weeklyData.map(module => new Date(module.date).toLocaleDateString('pt-BR', options));

            const minPressureData = weeklyData
                .filter(module => module.min_pressure !== -99 && module.min_pressure != null)
                .map(module => parseFloat(module.min_pressure.toFixed(1)));

            const maxPressureData = weeklyData
                .filter(module => module.max_pressure !== -99 && module.max_pressure != null)
                .map(module => parseFloat(module.max_pressure.toFixed(1)));

            const minPressureIndex = minPressureData.findIndex(pressure => pressure === Math.min(...minPressureData));
            const maxPressureIndex = maxPressureData.findIndex(pressure => pressure === Math.max(...maxPressureData));

            const minPressureDay = labels[minPressureIndex];
            const maxPressureDay = labels[maxPressureIndex];

            modulesLabel = `
                    Dia ${minPressureDay}, pressão mínima: ${minPressureData[minPressureIndex]} hPa
                    Dia ${maxPressureDay}, pressão máxima: ${maxPressureData[maxPressureIndex]} hPa
                `;

            const minPressureDataset = {
                label: 'Pressão Mínima (hPa)',
                data: minPressureData,
                yAxisID: 'pressure',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxPressureDataset = {
                label: 'Pressão Máxima (hPa)',
                data: maxPressureData,
                yAxisID: 'pressure',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartPressureWeek');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minPressureDataset, maxPressureDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            pressure: {
                                id: 'pressure',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.PRESSAO_UNI,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'windSpeedWeek') {
            labels = weeklyData.map(module => new Date(module.date).toLocaleDateString('pt-BR', options));

            const minWindSpeedData = weeklyData
                .filter(module => module.min_wind_speed !== -99 && module.min_wind_speed != null)
                .map(module => parseFloat(module.min_wind_speed.toFixed(1)));

            const maxWindSpeedData = weeklyData
                .filter(module => module.max_wind_speed !== -99 && module.max_wind_speed != null)
                .map(module => parseFloat(module.max_wind_speed.toFixed(1)));

            const minWindSpeedIndex = minWindSpeedData.findIndex(speed => speed === Math.min(...minWindSpeedData));
            const maxWindSpeedIndex = maxWindSpeedData.findIndex(speed => speed === Math.max(...maxWindSpeedData));

            const minWindSpeedDay = labels[minWindSpeedIndex];
            const maxWindSpeedDay = labels[maxWindSpeedIndex];

            modulesLabel = `
                Dia ${minWindSpeedDay || '-'}, vel. do vento mínima: ${minWindSpeedData[minWindSpeedIndex] !== undefined ? minWindSpeedData[minWindSpeedIndex] + ' km/h' : '-'}
                Dia ${maxWindSpeedDay || '-'}, vel. do vento máxima: ${maxWindSpeedData[maxWindSpeedIndex] !== undefined ? maxWindSpeedData[maxWindSpeedIndex] + ' km/h' : '-'}
            `;

            const minWindSpeedDataset = {
                label: 'Velocidade do Vento Mínima (km/h)',
                data: minWindSpeedData,
                yAxisID: 'windSpeed',
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1
            };

            const maxWindSpeedDataset = {
                label: 'Velocidade do Vento Máxima (km/h)',
                data: maxWindSpeedData,
                yAxisID: 'windSpeed',
                backgroundColor: '#FA3834',
                borderColor: '#FA3834',
                borderWidth: 1
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartWindSpeedWeek');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [minWindSpeedDataset, maxWindSpeedDataset]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                        },
                        scales: {
                            windSpeed: {
                                id: 'windSpeed',
                                type: 'linear',
                                position: 'left',
                                title: {
                                    display: true,
                                    text: res.VELOCIDADE_VENTO,
                                    color: 'white',
                                },
                                ticks: {
                                    color: 'lightgray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'lightgray',
                                }
                            }
                        },
                    }
                });
            }
        } else if (selectedInfo === 'rainWeek') {
            labels = weeklyData.map(module => new Date(module.date).toLocaleDateString('pt-BR', options));

            const rainData = weeklyData
                .map(module => module.total_rain !== undefined && module.total_rain !== -99 && module.total_rain != null && module.total_rain >= 0
                    ? parseFloat(module.total_rain.toFixed(1))
                    : 0);

            const totalRainfall = rainData.reduce((acc, curr) => acc + curr, 0);

            modulesLabel = `Total de chuva na semana: ${totalRainfall.toFixed(1)} mm`;

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartElement = document.getElementById('chartRainWeek');
            if (chartElement) {
                const ctx = chartElement.getContext('2d');
                this.barChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: 'Chuva diária (mm)',
                            data: rainData,
                            backgroundColor: '#FA9F34',
                            borderColor: '#FA9F34',
                            borderWidth: 1,
                            pointBackgroundColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            pointBorderColor: rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34'),
                            fill: false,
                        }],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    color: 'white',
                                },
                                title: {
                                    display: true,
                                    text: `${res.CHUVA} (mm)`,
                                    color: 'white',
                                },
                            },
                            x: {
                                ticks: {
                                    color: 'white',
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: 'white',
                                }
                            },
                            tooltip: {
                                mode: 'index',
                                intersect: false,
                            },
                            title: {
                                display: true,
                                text: modulesLabel,
                                color: 'white',
                                position: 'bottom',
                            },
                        },
                        elements: {
                            line: {
                                tension: 0.1
                            },
                            point: {
                                radius: 3
                            }
                        },
                    },
                });
            }
        }
    }

    handleTabClick = (selectedInfo) => {
        this.setState({
            selectedInfo,
            weeklyData: [],
            monthlyData: [],
            yearlyData: [],
            filterStations: []
        });
    };

    render() {
        const { classes, res } = this.props;
        const { stations, stationData, idStationSelected, autoUpdate, goTo, consultForecastData, showData, disable, maxSelectedDate, selectedDate, filterStations, selectedInfo,
            addressStationL, lat, lng, selectedTime, latL, lngL, nameL, heightL, datetimeL, air_humidityL, air_temperatureL, wind_directionL, pressureL, wind_speedL, rainL, solarL,
            flightConditions, delta_tL, thermalInversionL, weatherForecastData, isOpenStation, isOpenWeatherForecast, addressExists, isMapExpanded, loading, selectedHistoricType,
            monthlyData, selectedMonth, selectedYear, yearlyData, weeklyData, selectedWeekStart, selectedWeekEnd
        } = this.state;

        if (isOpenStation && isOpenWeatherForecast) {
            if (stationData[0]) {
                this.mymap.setView([stationData[0].lat, parseFloat(stationData[0].lng) - parseFloat('00.037')], 14);
            }
        }

        return (
            <div>
                <div className="divContent pageTitleContainer" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="containerTitle">
                        <h1 className="pageTitle">{res.CLIMA}</h1>
                    </div>
                    <Select
                        labelId="station-select-label"
                        id="station-select"
                        value={idStationSelected}
                        onChange={(event) => this.handleListItemClick(event.target.value)}
                        style={{ margin: '0 3rem', width: '12rem', textAlign: 'center' }}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}
                    >
                        {stations.map((item) => {
                            const station = stationData.find((filterStation) => filterStation.id_source === item.id);
                            const idStation = station ? station.id_source : null;

                            return (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                    style={{ color: idStation === idStationSelected ? 'selectedColor' : 'defaultColor' }}
                                >
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <div className="leftTop" style={{ paddingLeft: isOpenStation ? '2.5rem' : '0' }}>
                        {autoUpdate
                            ?
                            <Button style={{ backgroundColor: 'rgb(250, 125, 52)', color: 'white', fontSize: '1.5rem', border: '1px solid rgba(0, 0, 0, 0.23)' }} title='Desativar atualização automática' onClick={this.toggleAutoUpdate}>
                                <UpdateIcon />
                            </Button>
                            :
                            <Button style={{ fontSize: '1.5rem', border: '1px solid rgba(0, 0, 0, 0.23)' }} title='Ativar atualização automática' onClick={this.toggleAutoUpdate}>
                                <UpdateIcon />
                            </Button>
                        }
                    </div>
                </div>
                <div className="maxVH" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ width: '78.25%', marginRight: '0.5rem' }}>
                            {loading ?
                                <div style={{ backgroundColor: 'rgb(52, 58, 64)', padding: '1rem', height: '10.75rem', borderRadius: '0.5rem' }}>
                                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: '0' }}
                                            width={240}
                                            height={24}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: '0' }}
                                            height={80}
                                            width={120}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: '0' }}
                                            height={80}
                                            width={120}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: '0' }}
                                            height={80}
                                            width={120}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: '0' }}
                                            height={80}
                                            width={120}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: '0' }}
                                            height={80}
                                            width={120}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: '0' }}
                                            height={80}
                                            width={120}
                                        />
                                    </div>
                                    <Skeleton variant="text" style={{ margin: '0' }} height={40} />
                                </div>
                                :
                                <div style={{ backgroundColor: 'rgb(52, 58, 64)', padding: '0.25rem', height: '10.75rem', borderRadius: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 0.5rem' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <span style={{ color: 'white', fontSize: '1.5rem' }}>{datetimeL}</span>
                                            {addressStationL === 255 && (
                                                <>
                                                    {rainL > 0 ? (
                                                        solarL < 3 ? (
                                                            <img style={{ width: '3.5rem', padding: '0.5rem' }} src={rainNight} alt="imagem chuva noite" />
                                                        ) : (
                                                            <img style={{ width: '3.5rem', padding: '0.5rem' }} src={rainDay} alt="imagem chuva dia" />
                                                        )
                                                    ) : (
                                                        solarL < 3 ? (
                                                            <img style={{ width: '3.5rem', padding: '0.5rem' }} src={lua} alt="imagem lua" />
                                                        ) : (
                                                            <img style={{ width: '3.5rem', padding: '0.5rem' }} src={sun} alt="imagem sol" />
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span></span>
                                            {air_temperatureL && air_temperatureL !== 'Sem conexão' ?
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>{air_temperatureL}<span style={{ fontSize: '1rem' }}> °C</span></span>
                                                :
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>-</span>
                                            }
                                            <span style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.TEMPERATURA_}</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span></span>
                                            {air_humidityL && air_humidityL !== 'Sem conexão' ?
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>{air_humidityL}<span style={{ fontSize: '1rem' }}> %</span></span>
                                                :
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>-</span>
                                            }
                                            <span style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.UMIDADE_}</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span></span>
                                            {wind_speedL && wind_speedL !== 'Sem conexão' ?
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>{wind_speedL}<span style={{ fontSize: '1rem' }}> km/h</span></span>
                                                :
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>-</span>
                                            }
                                            <span style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.VENTO_VELOCIDADE}</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {delta_tL && delta_tL !== 'Sem conexão' ?
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <span style={{ color: flightConditions === 'Condições adequadas' ? 'lawngreen' : flightConditions === 'Condições inadequadas' ? 'red' : 'yellow', fontSize: '1.75rem' }}>{delta_tL}</span>
                                                    <span style={{ color: flightConditions === 'Condições adequadas' ? 'lawngreen' : flightConditions === 'Condições inadequadas' ? 'red' : 'yellow' }}>{flightConditions}</span>
                                                </div>
                                                :
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>-</span>
                                            }
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.DELTA_T}</span>
                                                <Tooltip
                                                    title={
                                                        <div
                                                            style={{
                                                                backgroundColor: '#fff',
                                                                padding: '10px',
                                                                borderRadius: '4px',
                                                                boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                                width: '22.5rem'
                                                            }}
                                                        >
                                                            <DeltaTHeat
                                                                atual={delta_tL}
                                                                humidade={air_humidityL}
                                                                temperatura={air_temperatureL}
                                                            />
                                                        </div>
                                                    }
                                                    placement="top"
                                                    arrow
                                                    classes={{ tooltip: classes.customWidthTooltip }}
                                                >
                                                    <HelpOutlineIcon style={{ color: 'rgb(250, 125, 52)', marginBottom: '0', width: '1.25rem', marginLeft: '0.5rem' }} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {wind_directionL && wind_directionL !== 'Sem conexão' ?
                                                <div style={{ display: 'flex', textAlign: 'center' }}>
                                                    <span></span>
                                                    <span style={{ color: 'white', fontSize: '1.75rem', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                        <p style={{ marginRight: '1rem', marginBottom: '0' }}>{`${wind_directionL} °`}</p>
                                                        <div className="windDirectionIcon">
                                                            <div className="circle">
                                                                <div className="windDirection north">N</div>
                                                                <div className="windDirection south">S</div>
                                                                <div className="windDirection east">L</div>
                                                                <div className="windDirection west">O</div>
                                                            </div>
                                                            <div className="windDirectionArrow" style={{ transform: `rotate(${wind_directionL}deg)`, padding: '0.5rem 0 0.25rem 0' }}>
                                                                <NavigationIcon style={{ fontSize: '1rem', color: 'white' }} />
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                :
                                                <span style={{ color: 'white', fontSize: '1.75rem' }}>-</span>
                                            }
                                            <span style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.DIRECAO_VENTO}</span>
                                        </div>
                                    </div>
                                    <div className="historyCardContainer">
                                        {Array.from({ length: 24 }, (_, index) => {
                                            const hour = index;
                                            const isSelected = selectedTime === hour;
                                            const today = Moment().startOf('day');
                                            const selectedDay = Moment(selectedDate).startOf('day');
                                            const isToday = today.isSame(selectedDay, 'day');

                                            let isDisabled = false;
                                            if (isToday) {
                                                isDisabled = hour > Moment().hour();
                                            } else if (selectedDay.isBefore(today, 'day')) {
                                                isDisabled = false;
                                            } else {
                                                isDisabled = true;
                                            }

                                            const stationData = this.state.sliderData.find(data => Moment(data.datetime).hour() === hour);
                                            const temperature = stationData ? Number(stationData.air_temperature).toFixed(1) : '-';
                                            const isRainy = stationData && stationData.rain > 0;
                                            const isSunny = stationData && stationData.solar >= 3;
                                            const isNight = stationData && stationData.solar < 3;
                                            const addressStationL = stationData ? stationData.addressStation : null;

                                            return (
                                                <button
                                                    key={index}
                                                    className={`historyCard ${isSelected ? 'selected' : ''}`}
                                                    onClick={() => this.handleCardClick(hour)}
                                                    disabled={isDisabled}
                                                    style={{
                                                        backgroundColor: isSelected ? 'rgb(250, 125, 52)' : 'rgb(32 35 39)',
                                                        color: isDisabled ? 'gray' : 'white',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div>{String(hour).padStart(2, '0')}:00</div>
                                                    {isRainy ? (
                                                        isNight ? (
                                                            <img style={{ width: '1rem' }} src={rainNight} alt='Chuva à noite' />
                                                        ) : (
                                                            <img style={{ width: '1rem' }} src={rainDay} alt='Chuva durante o dia' />
                                                        )
                                                    ) : (
                                                        isSunny ? (
                                                            <img style={{ width: '1rem' }} src={sun} alt="Sol" />
                                                        ) : (
                                                            isNight && addressStationL === 255 ? (
                                                                <img style={{ width: '1rem' }} src={lua} alt="Lua" />
                                                            ) : null
                                                        )
                                                    )}
                                                    <div>
                                                        {temperature !== '-' ? (
                                                            <div>
                                                                <span style={{ fontSize: '0.75rem' }}>{temperature}</span>
                                                                <span style={{ fontSize: '0.625rem' }}>°C</span>
                                                            </div>
                                                        ) : (
                                                            <span>-</span>
                                                        )}
                                                    </div>
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                            <div style={{ backgroundColor: 'rgb(52, 58, 64)', marginTop: '0.5rem', height: '22.5rem', borderRadius: '0.5rem' }}>
                                <div className="configurationDataContainer">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h3 className="titleHistoric">{res.HISTORICO}</h3>
                                        <Tabs
                                            value={selectedHistoricType}
                                            onChange={this.handleTabHistoricChange}
                                            classes={{ indicator: classes.tabsIndicator }}
                                            style={{ background: 'rgb(32 35 39)', borderRadius: '2rem', margin: '0.5rem 0.5rem 0 0.5rem', color: 'lightgray', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }}
                                        >
                                            <Tab onClick={() => this.handleTabClick('weatherChart')} style={{ minWidth: '5rem', padding: '0 1rem', margin: '1rem 0', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: classes.tab }} value='daily' label='Diário' />
                                            <Tab onClick={() => this.handleTabClick('temperatureWeek')} style={{ minWidth: '5rem', padding: '0 1rem', margin: '1rem 0', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: classes.tab }} value='week' label='Semanal' />
                                            <Tab onClick={() => this.handleTabClick('temperatureMonth')} style={{ minWidth: '5rem', padding: '0 1rem', margin: '1rem 0', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: classes.tab }} value='month' label='Mensal' />
                                            <Tab onClick={() => this.handleTabClick('temperatureYear')} style={{ minWidth: '5rem', padding: '0 1rem', margin: '1rem 0', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: classes.tab }} value='year' label='Anual' />
                                        </Tabs>
                                    </div>
                                    <div className="selectStationContainer">
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div className="dateContainer">
                                                <div style={{ textAlign: 'center' }}>
                                                    {loading ? (
                                                        <>
                                                            {selectedHistoricType === 'daily' ? (
                                                                <Skeleton
                                                                    variant="text"
                                                                    style={{ margin: '0 1rem' }}
                                                                    width={200}
                                                                    height={32}
                                                                />
                                                            ) : selectedHistoricType === 'month' ? (
                                                                <Skeleton
                                                                    variant="text"
                                                                    style={{ margin: '0 1rem' }}
                                                                    width={200}
                                                                    height={32}
                                                                />
                                                            ) : selectedHistoricType === 'week' ? (
                                                                <>
                                                                    <Skeleton
                                                                        variant="text"
                                                                        style={{ margin: '0 1rem' }}
                                                                        width={200}
                                                                        height={32}
                                                                    />
                                                                    <Skeleton
                                                                        variant="text"
                                                                        style={{ margin: '0 1rem' }}
                                                                        width={200}
                                                                        height={32}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <Skeleton
                                                                    variant="text"
                                                                    style={{ margin: '0 1rem' }}
                                                                    width={200}
                                                                    height={32}
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {selectedHistoricType === 'daily' ? (
                                                                <TextField
                                                                    style={{ margin: '0 1rem', width: '12.5rem' }}
                                                                    id="selectedDate"
                                                                    type="date"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            max: maxSelectedDate
                                                                        },
                                                                        className: classes.inputDate
                                                                    }}
                                                                    onChange={this.handleDateChange}
                                                                    value={selectedDate}
                                                                    className="inputDate"
                                                                />
                                                            ) : selectedHistoricType === 'month' ? (
                                                                <TextField
                                                                    style={{ margin: '0 1rem', width: '12.5rem' }}
                                                                    id="selectedMonth"
                                                                    type="month"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            max: maxSelectedDate
                                                                        },
                                                                        className: classes.inputDate
                                                                    }}
                                                                    onChange={this.handleMonthChange}
                                                                    value={selectedMonth}
                                                                    className="inputDate"
                                                                />
                                                            ) : selectedHistoricType === 'week' ? (
                                                                <>
                                                                    <TextField
                                                                        style={{ margin: '0 1rem', width: '12.5rem' }}
                                                                        id="selectedWeekStart"
                                                                        type="date"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                max: maxSelectedDate
                                                                            },
                                                                            className: classes.inputDate
                                                                        }}
                                                                        onChange={this.handleWeekChange}
                                                                        value={selectedWeekStart}
                                                                        className="inputDate"
                                                                    />
                                                                    <TextField
                                                                        style={{ margin: '0 1rem', width: '12.5rem' }}
                                                                        id="selectedWeekEnd"
                                                                        type="date"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                readOnly: true
                                                                            },
                                                                            className: classes.inputDate
                                                                        }}
                                                                        value={selectedWeekEnd}
                                                                        className="inputDate"
                                                                    />
                                                                </>
                                                            ) : (
                                                                <Select
                                                                    className={classes.select}
                                                                    value={selectedYear}
                                                                    onChange={this.handleYearChange}
                                                                    inputProps={{
                                                                        classes: {
                                                                            icon: classes.icon,
                                                                        },
                                                                    }}
                                                                >
                                                                    {Array.from({ length: 10 }, (_, i) => (
                                                                        <MenuItem
                                                                            key={i}
                                                                            value={new Date().getFullYear() - i}
                                                                            className={classes.menuItem}
                                                                        >
                                                                            {new Date().getFullYear() - i}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div style={{ display: 'flex', textAlign: 'center' }}>
                                                    <Button
                                                        style={{
                                                            height: '1.5rem',
                                                            background: loading ? 'rgb(32 35 39)' : 'rgb(250, 125, 52)',
                                                            width: '4.25rem',
                                                            borderRadius: '1rem',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            lineHeight: '0',
                                                            color: loading ? 'lightgray' : 'white',
                                                            margin: '0 0.5rem'
                                                        }}
                                                        className="dateButton"
                                                        variant="contained"
                                                        onClick={
                                                            selectedHistoricType === 'daily'
                                                                ? this.handleFilterDate
                                                                : selectedHistoricType === 'month'
                                                                    ? this.readWeatherDataByMonth
                                                                    : selectedHistoricType === 'week'
                                                                        ? this.readWeatherDataByWeek
                                                                        : this.readWeatherDataByYear
                                                        }
                                                        disabled={loading ? 'disabled' : null}
                                                    >
                                                        {res.FILTRAR}
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            height: '1.5rem',
                                                            background: 'rgb(32 35 39)',
                                                            width: '4.25rem',
                                                            borderRadius: '1rem',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            lineHeight: '0',
                                                        }}
                                                        disabled={loading ? 'disabled' : null}
                                                        className='restartFilterButton' title={res.REINICIAR_FILTRO} onClick={this.clearFilters} variant="contained">
                                                        <RestartIcon style={{ color: loading ? 'lightgray' : 'white' }} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedHistoricType === 'daily' ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0.5rem', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <Tabs
                                                style={{ background: 'rgb(32 35 39)', borderRadius: '2rem', color: filterStations.length > 0 ? 'lightgray' : 'gray', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }}
                                                value={selectedInfo}
                                                onChange={filterStations.length > 0 ? this.handleTabChange : null}
                                                classes={{
                                                    indicator: classes.tabsIndicator
                                                }}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                            >
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.CLIMA} value="weatherChart" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.TEMPERATURA_UNI} value="temperature" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.HUMIDADE_UNI} value="humidity" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.PRESSAO_UNI} value="pressure" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.DIRECAO_VENTO_UNI} value="wind_direction" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.VELOCIDADE_VENTO} value="wind_speed" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.DELTA_T} value="delta_t" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : classes.tab }} label={res.INVERSAO_TERMICA} value="thermalInversion" disabled={filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : addressExists ? classes.tab : classes.tabDisabled }} label={res.CHUVA} value="rain" disabled={!addressExists || filterStations.length === 0} />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: filterStations.length === 0 ? classes.tabDisabled : addressExists ? classes.tab : classes.tabDisabled }} label={res.RADIACAO_SOLAR} value="solar" disabled={!addressExists || filterStations.length === 0} />
                                            </Tabs>
                                        </div>
                                        <div>
                                            {this.showChart()}
                                        </div>
                                    </div>
                                ) : selectedHistoricType === 'month' ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0.5rem', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <Tabs
                                                style={{ background: 'rgb(32 35 39)', borderRadius: '2rem', color: 'lightgray', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }}
                                                value={selectedInfo}
                                                onChange={this.handleTabChange}
                                                classes={{
                                                    indicator: classes.tabsIndicator
                                                }}
                                            >
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: monthlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.TEMPERATURA_UNI} value="temperatureMonth" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: monthlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.HUMIDADE_UNI} value="humidityMonth" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: monthlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.PRESSAO_UNI} value="pressureMonth" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: monthlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.VELOCIDADE_VENTO} value="windSpeedMonth" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: monthlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.CHUVA} value="rainMonth" />
                                            </Tabs>
                                        </div>
                                        <div>
                                            {this.showChart()}
                                        </div>
                                    </div>
                                ) : selectedHistoricType === 'week' ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0.5rem', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <Tabs
                                                style={{ background: 'rgb(32 35 39)', borderRadius: '2rem', color: 'lightgray', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }}
                                                value={selectedInfo}
                                                onChange={this.handleTabChange}
                                                classes={{
                                                    indicator: classes.tabsIndicator
                                                }}
                                            >
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: weeklyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.TEMPERATURA_UNI} value="temperatureWeek" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: weeklyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.HUMIDADE_UNI} value="humidityWeek" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: weeklyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.PRESSAO_UNI} value="pressureWeek" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: weeklyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.VELOCIDADE_VENTO} value="windSpeedWeek" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: weeklyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.CHUVA} value="rainWeek" />
                                            </Tabs>
                                        </div>
                                        <div>
                                            {this.showChart()}
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '0 0.5rem', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <Tabs
                                                style={{ background: 'rgb(32 35 39)', borderRadius: '2rem', color: 'lightgray', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }}
                                                value={selectedInfo}
                                                onChange={this.handleTabChange}
                                                classes={{
                                                    indicator: classes.tabsIndicator
                                                }}
                                            >
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: yearlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.TEMPERATURA_UNI} value="temperatureYear" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: yearlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.HUMIDADE_UNI} value="humidityYear" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: yearlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.PRESSAO_UNI} value="pressureYear" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: yearlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.VELOCIDADE_VENTO} value="windSpeedYear" />
                                                <Tab style={{ minWidth: '5rem', padding: '0 1rem', minHeight: '0', height: '2rem', display: 'flex', alignItems: 'center' }} classes={{ root: yearlyData.length === 0 ? classes.tabDisabled : classes.tab }} label={res.CHUVA} value="rainYear" />
                                            </Tabs>
                                        </div>
                                        <div>
                                            {this.showChart()}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {loading ?
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="weatherCard">
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            width={120}
                                            height={32}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            height={160}
                                            width={120}
                                        />
                                    </div>
                                    <div className="weatherCard">
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            width={120}
                                            height={32}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            height={160}
                                            width={120}
                                        />
                                    </div>
                                    <div className="weatherCard">
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            width={120}
                                            height={32}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            height={160}
                                            width={120}
                                        />
                                    </div>
                                    <div className="weatherCard">
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            width={120}
                                            height={32}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            height={160}
                                            width={120}
                                        />
                                    </div>
                                    <div className="weatherCard">
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            width={120}
                                            height={32}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            height={160}
                                            width={120}
                                        />
                                    </div>
                                    <div className="weatherCard">
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            width={120}
                                            height={32}
                                        />
                                        <Skeleton
                                            variant="text"
                                            style={{ margin: "0" }}
                                            height={160}
                                            width={120}
                                        />
                                    </div>
                                </div>
                                :
                                <div style={{ display: 'flex' }}>
                                    <div className="weatherCard">
                                        <div style={{
                                            backgroundColor: 'rgb(250, 125, 52)',
                                            borderRadius: '50%',
                                            width: '2rem',
                                            height: '2rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            top: '46.25rem',
                                            zIndex: 2
                                        }}>
                                            <img style={{ width: '1.5rem', height: '1.5rem' }} title='Inversão térmica' src={thermalInversion} alt="thermal inversion icon" />
                                        </div>
                                        <div style={{ display: 'flex', textAlign: 'center', paddingTop: '1rem' }}>
                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.INVERSAO_TERMICA}</h3>
                                            <Tooltip
                                                title={
                                                    <div
                                                        style={{
                                                            backgroundColor: '#fff',
                                                            padding: '10px',
                                                            borderRadius: '4px',
                                                            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                            width: '25.25rem'
                                                        }}
                                                    >
                                                        {thermalInversionL === "Não está ocorrendo inversão térmica"
                                                            ?
                                                            <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={normalFlowExample} alt="Fluxo normal" />
                                                            :
                                                            <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={thermalInversionExample} alt="Fluxo com inversão térmica" />
                                                        }

                                                    </div>
                                                }
                                                placement="top"
                                                arrow
                                                classes={{ tooltip: classes.customWidthTooltip }}
                                            >
                                                <HelpOutlineIcon style={{ color: 'rgb(250, 125, 52)', marginBottom: '0', width: '1.25rem', marginLeft: '0.5rem' }} />
                                            </Tooltip>
                                        </div>
                                        <span style={{ color: 'white', fontSize: '1rem', textAlign: 'center' }}>{thermalInversionL}</span>
                                    </div>
                                    <div className="weatherCard">
                                        <div style={{
                                            backgroundColor: 'rgb(250, 125, 52)',
                                            borderRadius: '50%',
                                            width: '2rem',
                                            height: '2rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            top: '46.25rem',
                                            zIndex: 2
                                        }}>
                                            <img style={{ width: '1.5rem', height: '1.5rem' }} title='Latitude' src={latitude} alt="latitude icon" />
                                        </div>
                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.LATITUDE}</h3>
                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.GRAUS_MINUTOS_SEGUNDOS})`}</h4>
                                        </div>
                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{latL.input}</span>
                                    </div>
                                    <div className="weatherCard">
                                        <div style={{
                                            backgroundColor: 'rgb(250, 125, 52)',
                                            borderRadius: '50%',
                                            width: '2rem',
                                            height: '2rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            top: '46.25rem',
                                            zIndex: 2
                                        }}>
                                            <img style={{ width: '1.5rem', height: '1.5rem' }} title='Longitude' src={longitude} alt="longitude icon" />
                                        </div>
                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.LONGITUDE}</h3>
                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.GRAUS_MINUTOS_SEGUNDOS})`}</h4>
                                        </div>
                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{lngL.input}</span>
                                    </div>
                                    <div className="weatherCard">
                                        <div style={{
                                            backgroundColor: 'rgb(250, 125, 52)',
                                            borderRadius: '50%',
                                            width: '2rem',
                                            height: '2rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            top: '46.25rem',
                                            zIndex: 2
                                        }}>
                                            <img style={{ width: '1.5rem', height: '1.5rem' }} title='Altitude' src={altitude} alt="altitude icon" />
                                        </div>
                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.ALTITUDE}</h3>
                                            <h4 style={{ color: 'rgb(250, 125, 52)' }}>{`(${res.METROS})`}</h4>
                                        </div>
                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{heightL === 'Sem conexão' ? 'Sem conexão' : `${heightL} m`}</span>
                                    </div>
                                    <div className="weatherCard">
                                        <div style={{
                                            backgroundColor: 'rgb(250, 125, 52)',
                                            borderRadius: '50%',
                                            width: '2rem',
                                            height: '2rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            top: '46.25rem',
                                            zIndex: 2
                                        }}>
                                            <img style={{ width: '1.5rem', height: '1.5rem' }} title='Pressão' src={pressure} alt="pressure icon" />
                                        </div>
                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.PRESSAO}</h3>
                                            <h4 style={{ color: 'rgb(250, 125, 52)' }}>{`(${res.HECTOPASCAL})`}</h4>
                                        </div>
                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{pressureL === 'Sem conexão' ? 'Sem conexão' : `${pressureL} hPa `}</span>
                                    </div>
                                    <div className="weatherCard" style={{ margin: '0.5rem 0' }}>
                                        <div style={{
                                            backgroundColor: 'rgb(250, 125, 52)',
                                            borderRadius: '50%',
                                            width: '2rem',
                                            height: '2rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            top: '46.25rem',
                                            zIndex: 2
                                        }}>
                                            <img style={{ width: '1.5rem', height: '1.5rem' }} title='Condições climáticas' src={condicoes_climaticas} alt="condicoes climaticas icon" />
                                        </div>
                                        <div>
                                            <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                <h3 style={{ color: 'rgb(250, 125, 52)', textAlign: 'center' }}>{res.CONDICOES_CLIMATICAS}</h3>
                                            </div>
                                        </div>
                                        {addressStationL == 255
                                            ?
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '1.5rem', width: '100%' }}>
                                                {rainL !== 0
                                                    ?
                                                    <img className="modulesIcon" title={res.CHUVA} src={rainOn} alt="rain icon" />
                                                    :
                                                    <img className="modulesIcon" title='Sem chuva' src={rainOff} alt="rain icon" />
                                                }
                                                {solarL !== 0
                                                    ?
                                                    <img className="modulesIcon" title='Radiação solar' src={solarOn} alt="solar icon" />
                                                    :
                                                    <img className="modulesIcon" title='Sem radiação solar' src={solarOff} alt="solar icon" />
                                                }
                                            </div>
                                            :
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                                <img className="modulesIcon" title={`${res.CHUVA} (Desativado)`} src={rainOff} alt="rain icon" />
                                                <img className="modulesIcon" title='Radiação solar (Desativado)' src={solarOff} alt="solar icon" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        <div style={{ width: '21.75%' }}>
                            <div className="divContent divRelative map-p" style={{ backgroundColor: 'rgb(52, 58, 64)', height: '16rem', width: '22rem', padding: '0', borderRadius: '0.5rem' }}>
                                <div className="containerTop" style={{ padding: '0' }}></div>
                                <div className={`wrapperMap ${isMapExpanded ? classes.expandedMapStyles : {}}`}>
                                    <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '1111', display: !disable ? 'none' : 'block' }}></div>

                                    <div id="mapid" className={classes.mapCommonStyle}></div>
                                    <div className="zoom">
                                        <Button className="buttonS buttonMenu" onClick={() => this.zoomInL()}>
                                            <MaterialIcon icon="add" className="iconMap" />
                                        </Button>
                                        <Button className="buttonS buttonMenu" onClick={() => this.zoomOutL()}>
                                            <MaterialIcon icon="remove" className="iconMap" />
                                        </Button>
                                        {isMapExpanded ?
                                            <div>
                                                <Button className={goTo === true ? "buttonS buttonMenuWeather" : "buttonS buttonMenu"} title={res.LOCALIZAR_ESTACAO} onClick={() => this.showGoTo()}>
                                                    <MaterialIcon icon="search" className="iconMap" />
                                                </Button>
                                                <Button className={consultForecastData === true ? "buttonS buttonMenuWeather" : "buttonS buttonMenu"} title={res.PREVISAO_TEMPO} onClick={() => this.consultForecast()}>
                                                    <FilterDramaIcon className="iconMap" />
                                                </Button>
                                                <Button className={showData === true ? "buttonS buttonMenuWeather" : "buttonS buttonMenu"} title={`${res.DADOS_ESTACAO} ${nameL}`} onClick={() => this.showMapData()}>
                                                    <img style={{ width: '2rem', height: '2rem' }} src={weatherData} alt="Dados da estação icon" />

                                                </Button>
                                            </div>
                                            : null
                                        }

                                        <Button
                                            className={`buttonS ${consultForecastData ? 'buttonMenuWeather' : 'buttonMenu'} ${isMapExpanded ? classes.buttonFullScreenStyles : classes.buttonNormalStyles}`}
                                            title={isMapExpanded ? res.DIMINUIR_TELA : res.AUMENTAR_TELA}
                                            onClick={this.toggleMapSize}
                                        >
                                            {isMapExpanded ? <FullscreenExitIcon className="iconMap" /> : <FullscreenIcon className="iconMap" />}
                                        </Button>

                                        {isMapExpanded && showData ?
                                            <div style={{ display: 'flex', flexDirection: 'column', position: 'fixed', left: '0', right: '0', bottom: '0.5rem', margin: '0 0.5rem', backgroundColor: 'rgba(52, 58, 64, 0.85)', justifyContent: 'space-around' }}>
                                                <h3 style={{ margin: '0', textAlign: 'center', color: 'white', fontSize: '1.25rem' }}>{nameL}</h3>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.TEMPERATURA_}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.GRAU_CELSIUS})`}</h4>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{air_temperatureL} °C</span>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.UMIDADE_}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.PERCENTUAL})`}</h4>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{air_humidityL} %</span>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.VENTO_VELOCIDADE}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.KM_H})`}</h4>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{wind_speedL} km/h</span>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.DELTA_T}</h3>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                                            <span style={{ color: 'white', fontSize: '1.25rem' }}>{delta_tL}</span>
                                                            <span style={{ color: 'white', fontSize: '1rem' }}>{flightConditions}</span>
                                                        </div>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.DIRECAO_VENTO}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.GRAUS})`}</h4>

                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                            <p style={{ marginRight: '1rem', marginBottom: '0' }}>{`${wind_directionL} °`}</p>
                                                            <div className="windDirectionIcon">
                                                                <div className="circle">
                                                                    <div className="windDirection north">N</div>
                                                                    <div className="windDirection south">S</div>
                                                                    <div className="windDirection east">L</div>
                                                                    <div className="windDirection west">O</div>
                                                                </div>
                                                                <div className="windDirectionArrow" style={{ transform: `rotate(${wind_directionL}deg)`, padding: '0.5rem 0 0.25rem 0' }}>
                                                                    <NavigationIcon style={{ fontSize: '1rem', color: 'white' }} />
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.INVERSAO_TERMICA}</h3>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1rem', textAlign: 'center' }}>{thermalInversionL}</span>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.LATITUDE}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.GRAUS_MINUTOS_SEGUNDOS})`}</h4>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{latL.input}</span>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.LONGITUDE}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)', whiteSpace: 'nowrap' }}>{`(${res.GRAUS_MINUTOS_SEGUNDOS})`}</h4>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{lngL.input}</span>
                                                    </div>
                                                    <div className="weatherData">
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.ALTITUDE}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)' }}>{`(${res.METROS})`}</h4>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{heightL === 'Sem conexão' ? 'Sem conexão' : `${heightL} m`}</span>
                                                    </div>
                                                    <div className="weatherData" style={{ border: 'none' }}>
                                                        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                                                            <h3 style={{ color: 'rgb(250, 125, 52)', marginBottom: '0' }}>{res.PRESSAO}</h3>
                                                            <h4 style={{ color: 'rgb(250, 125, 52)' }}>{`(${res.HECTOPASCAL})`}</h4>
                                                        </div>
                                                        <span style={{ color: 'white', fontSize: '1.25rem' }}>{pressureL === 'Sem conexão' ? 'Sem conexão' : `${pressureL} hPa `}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {isMapExpanded && showData ?
                                            <div
                                                id="divFloat"
                                                className="div-list divContentList "
                                                style={{
                                                    backgroundColor: 'rgba(52, 58, 64, 0.85)',
                                                    marginTop: '0.5rem',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    height: '27rem',
                                                    width: '20rem',
                                                    position: 'fixed',
                                                    borderRadius: '0.5rem 0.5rem 0 0',
                                                    left: '0.5rem',
                                                    bottom: '11.35rem',
                                                }}
                                                onMouseEnter={() => this.toggleHoverForecast(true)}
                                                onMouseLeave={() => this.toggleHoverForecast(false)}
                                            >
                                                <div style={{ textAlign: 'center', padding: '0.5rem' }}>
                                                    <h3 className="titleWeatherForecast">{res.PREVISAO_TEMPO}</h3>
                                                </div>
                                                {weatherForecastData.length === 0
                                                    ?
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                                    </div>
                                                    :
                                                    <div className="bodyListStations" style={{ maxHeight: '23rem' }}>
                                                        <List component="nav" aria-labelledby="areas" className="weatherListContainer" style={{ padding: '0' }}>
                                                            {weatherForecastData.map((weather) => {
                                                                return (
                                                                    <ListItem key={weather.id} style={{ padding: '0.25rem 0.5rem 0.5rem 0.5rem' }}>
                                                                        <ListItemText id={weather.id} style={{ padding: '0 0.5rem', margin: '0', backgroundColor: 'rgb(32 35 39)', borderRadius: '1rem' }}>
                                                                            <ul style={{ padding: '0', margin: '0' }}>
                                                                                <li className='weatherListItem'>
                                                                                    <input type='checkbox' id={weather.weatherData[0].dt} className='inputWeatherForecast' />
                                                                                    <label for={weather.weatherData[0].dt} className='labelWeatherForecast'>
                                                                                        {Moment(weather.date).format("DD/MM")}
                                                                                        {weather.climateConditions === 'Clouds' ? <img style={{ maxHeight: '2rem' }} title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                            return a.toUpperCase();
                                                                                        })} src={cloud} alt="cloud" />
                                                                                            : weather.climateConditions === 'Rain' ? <img style={{ maxHeight: '2rem' }} title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                return a.toUpperCase();
                                                                                            })} src={rainOn} alt="rain" />
                                                                                                : <img style={{ maxHeight: '2rem' }} title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                    return a.toUpperCase();
                                                                                                })} src={sun} alt="sun" />
                                                                                        }
                                                                                        {`${weather.minTemp.toFixed(0)}° / ${weather.maxTemp.toFixed(0)}°`}
                                                                                        <ChevronRightIcon className='arrowIcon' fontSize='medium' />
                                                                                    </label>
                                                                                    <div className='weatherListData'>
                                                                                        {weather.weatherData.map((item) => (
                                                                                            <ul key={item.dt} style={{ padding: '0.5rem', margin: '0' }}>
                                                                                                <li className='forecastInformation'>
                                                                                                    <b style={{ color: 'rgb(250, 125, 52)' }} >{Moment(item.dt_txt).format("HH")}h:</b>
                                                                                                    <ul style={{ padding: '0.5rem' }}>
                                                                                                        <li className='forecastInformation'>
                                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Temperatura' src={temperatureForecast} alt="temperature forecast" />
                                                                                                            {item.main.temp.toFixed(0)} °C
                                                                                                        </li>
                                                                                                        <li className='forecastInformation'>
                                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Umidade' src={humidityForecast} alt="humidity forecast" />
                                                                                                            {item.main.humidity} %
                                                                                                        </li>
                                                                                                        <li className='forecastInformation'>
                                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Pressão' src={pressureForecast} alt="pressure forecast" />
                                                                                                            {item.main.pressure} hPa
                                                                                                        </li>
                                                                                                        <li className='forecastInformation'>
                                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Velocidade do vento' src={windSpeedForecast} alt="wind speed forecast" />
                                                                                                            {(Number(item.wind.speed) * 3.6).toFixed(0)} km/h
                                                                                                        </li>
                                                                                                        <li className='forecastInformation' style={{ display: 'flex' }}>
                                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Direção do vento' src={windDirectionForecast} alt="wind direction forecast" />
                                                                                                            {/* {item.wind.deg} ° */}
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display: 'flex',
                                                                                                                    alignSelf: 'center',
                                                                                                                    transform: `rotate(${item.wind.deg}deg)`,
                                                                                                                    marginLeft: '0.5rem'
                                                                                                                }}
                                                                                                            >
                                                                                                                <NavigationIcon style={{ fontSize: '1.8rem', transform: `rotate(180deg)`, color: 'white' }} />
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li className='forecastInformation'>
                                                                                                            {item.weather[0].main === 'Clouds'
                                                                                                                ?
                                                                                                                <img
                                                                                                                    style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                                    src={cloud}
                                                                                                                    alt="cloud"
                                                                                                                    title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                                        return a.toUpperCase();
                                                                                                                    })}
                                                                                                                />
                                                                                                                :
                                                                                                                item.weather[0].main === 'Rain'
                                                                                                                    ?
                                                                                                                    <img
                                                                                                                        style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                                        src={rainOn}
                                                                                                                        alt="rain"
                                                                                                                        title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                                            return a.toUpperCase();
                                                                                                                        })}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    <img
                                                                                                                        style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                                        src={sun}
                                                                                                                        alt="sun"
                                                                                                                        title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                                            return a.toUpperCase();
                                                                                                                        })}
                                                                                                                    />
                                                                                                            }
                                                                                                            {item.weather[0].description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                                return a.toUpperCase();
                                                                                                            })}
                                                                                                        </li>
                                                                                                        {item.rain && item.rain['3h'] !== undefined && item.rain['3h'] > 0 ? (
                                                                                                            <li className='forecastInformation'>
                                                                                                                <img
                                                                                                                    style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                                    title='Quantidade de chuva'
                                                                                                                    src={rainForecast}
                                                                                                                    alt='rain forecast'
                                                                                                                />
                                                                                                                {item.rain['3h'].toFixed(2)} mm
                                                                                                            </li>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ul>
                                                                                        ))}
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>

                                    {goTo ?
                                        <div className="divContent containerToLocate" style={{ width: '15rem', padding: '0' }}>
                                            <div className="modalHeader">
                                                <div className="leftDivHeader"></div>
                                                <div className="centerDivHeader">
                                                    <h3 style={{ color: 'white', margin: '0' }}>{res.LOCALIZAR}</h3>
                                                </div>
                                                <div className="rightDivHeader">
                                                    <Button size="small" className="popUpButton" onClick={() => this.setState({ goTo: false })}>
                                                        <CloseIcon style={{ color: 'white' }} />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="locationContainer">
                                                <TextField
                                                    id="lat"
                                                    label={res.LATITUDE}
                                                    margin="normal"
                                                    error={lat.error}
                                                    onChange={this.handleChangeCoordinates('lat')}
                                                    value={lat.input}
                                                    placeholder={geo.toDMS(lat.value, 'latitude', 1)}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.toString().slice(0, 45)
                                                    }}
                                                />
                                                <TextField
                                                    id="lng"
                                                    label={res.LONGITUDE}
                                                    margin="normal"
                                                    error={lng.error}
                                                    onChange={this.handleChangeCoordinates('lng')}
                                                    value={lng.input}
                                                    placeholder={geo.toDMS(lng.value, 'longitude', 1)}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.toString().slice(0, 45)
                                                    }}
                                                />
                                                <div className="divForm">
                                                    <Button size="small" className="popUpButton" onClick={() => this.goToCoordinates()}>
                                                        {res.BUSCAR}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>

                            <div
                                id="divFloat"
                                className="div-list divContentList"
                                style={{ backgroundColor: 'rgb(52, 58, 64)', marginTop: '0.5rem', display: 'flex', flexDirection: 'column', height: '26.75rem', width: '22rem', borderRadius: '0.5rem' }}
                                onMouseEnter={() => this.toggleHoverForecast(true)}
                                onMouseLeave={() => this.toggleHoverForecast(false)}
                            >
                                <div style={{ textAlign: 'center', padding: '0.5rem' }}>
                                    <h3 className="titleWeatherForecast">{res.PREVISAO_TEMPO}</h3>
                                </div>
                                {weatherForecastData.length === 0
                                    ?
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                        <Skeleton style={{ margin: '0.5rem' }} variant="text" width={210} height={40} />
                                    </div>
                                    :
                                    <div className="bodyListStations" style={{ maxHeight: '23rem', borderRadius: '2rem' }}>
                                        <List component="nav" aria-labelledby="areas" className="weatherListContainer" style={{ padding: '0' }}>
                                            {weatherForecastData.map((weather) => {
                                                return (
                                                    <ListItem key={weather.id} style={{ padding: '0.25rem 0.5rem 0.5rem 0.5rem' }}>
                                                        <ListItemText id={weather.id} style={{ padding: '0 0.5rem', margin: '0', backgroundColor: 'rgb(32 35 39)', borderRadius: '1rem' }}>
                                                            <ul style={{ padding: '0', margin: '0' }}>
                                                                <li className='weatherListItem'>
                                                                    <input type='checkbox' id={weather.weatherData[0].dt} className='inputWeatherForecast' />
                                                                    <label for={weather.weatherData[0].dt} className='labelWeatherForecast'>
                                                                        {Moment(weather.date).format("DD/MM")}
                                                                        {weather.climateConditions === 'Clouds' ? <img style={{ maxHeight: '2rem' }} title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                            return a.toUpperCase();
                                                                        })} src={cloud} alt="cloud" />
                                                                            : weather.climateConditions === 'Rain' ? <img style={{ maxHeight: '2rem' }} title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                return a.toUpperCase();
                                                                            })} src={rainOn} alt="rain" />
                                                                                : <img style={{ maxHeight: '2rem' }} title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                    return a.toUpperCase();
                                                                                })} src={sun} alt="sun" />
                                                                        }
                                                                        {`${weather.minTemp.toFixed(0)}° / ${weather.maxTemp.toFixed(0)}°`}
                                                                        <ChevronRightIcon className='arrowIcon' fontSize='medium' />
                                                                    </label>
                                                                    <div className='weatherListData'>
                                                                        {weather.weatherData.map((item) => (
                                                                            <ul key={item.dt} style={{ padding: '0.5rem', margin: '0' }}>
                                                                                <li className='forecastInformation'>
                                                                                    <b style={{ color: 'rgb(250, 125, 52)' }} >{Moment(item.dt_txt).format("HH")}h:</b>
                                                                                    <ul style={{ padding: '0.5rem' }}>
                                                                                        <li className='forecastInformation'>
                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Temperatura' src={temperatureForecast} alt="temperature forecast" />
                                                                                            {item.main.temp.toFixed(0)} °C
                                                                                        </li>
                                                                                        <li className='forecastInformation'>
                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Umidade' src={humidityForecast} alt="humidity forecast" />
                                                                                            {item.main.humidity} %
                                                                                        </li>
                                                                                        <li className='forecastInformation'>
                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Pressão' src={pressureForecast} alt="pressure forecast" />
                                                                                            {item.main.pressure} hPa
                                                                                        </li>
                                                                                        <li className='forecastInformation'>
                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Velocidade do vento' src={windSpeedForecast} alt="wind speed forecast" />
                                                                                            {(Number(item.wind.speed) * 3.6).toFixed(0)} km/h
                                                                                        </li>
                                                                                        <li className='forecastInformation' style={{ display: 'flex' }}>
                                                                                            <img style={{ maxHeight: '2rem', marginRight: '0.5rem' }} title='Direção do vento' src={windDirectionForecast} alt="wind direction forecast" />
                                                                                            {/* {item.wind.deg} ° */}
                                                                                            <div
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    alignSelf: 'center',
                                                                                                    transform: `rotate(${item.wind.deg}deg)`,
                                                                                                    marginLeft: '0.5rem'
                                                                                                }}
                                                                                            >
                                                                                                <NavigationIcon style={{ fontSize: '1.8rem', transform: `rotate(180deg)`, color: 'white' }} />
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className='forecastInformation'>
                                                                                            {item.weather[0].main === 'Clouds'
                                                                                                ?
                                                                                                <img
                                                                                                    style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                    src={cloud}
                                                                                                    alt="cloud"
                                                                                                    title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                        return a.toUpperCase();
                                                                                                    })}
                                                                                                />
                                                                                                :
                                                                                                item.weather[0].main === 'Rain'
                                                                                                    ?
                                                                                                    <img
                                                                                                        style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                        src={rainOn}
                                                                                                        alt="rain"
                                                                                                        title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                            return a.toUpperCase();
                                                                                                        })}
                                                                                                    />
                                                                                                    :
                                                                                                    <img
                                                                                                        style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                        src={sun}
                                                                                                        alt="sun"
                                                                                                        title={weather.description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                            return a.toUpperCase();
                                                                                                        })}
                                                                                                    />
                                                                                            }
                                                                                            {item.weather[0].description.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                                                                                                return a.toUpperCase();
                                                                                            })}
                                                                                        </li>
                                                                                        {item.rain && item.rain['3h'] !== undefined && item.rain['3h'] > 0 ? (
                                                                                            <li className='forecastInformation'>
                                                                                                <img
                                                                                                    style={{ maxHeight: '2rem', marginRight: '0.5rem' }}
                                                                                                    title='Quantidade de chuva'
                                                                                                    src={rainForecast}
                                                                                                    alt='rain forecast'
                                                                                                />
                                                                                                {item.rain['3h'].toFixed(2)} mm
                                                                                            </li>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        ))}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </ListItemText>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

Map.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Map);

const Box = (props) => {
    const { link, res } = props;
    return (
        <div className="rowForm maxH" style={{ overflow: 'hidden' }}>
            <TextFields1 link={link} res={res} />
        </div>)
}
export default Box;
