import React from 'react';
import Config from '../../constants/appConfig';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles, Tooltip, MenuItem, Select, FormControl } from '@material-ui/core';
import Moment from 'moment';
import thermalInversionExample from '../../assets/images/thermalInversionExample.png';
import normalFlowExample from '../../assets/images/normalFlowExample.png';
import DeltaTHeat from '../../components/heat_graph/deltaTGraph';
import windArrowImage from '../../assets/images/marker/waypoint/marker-icon.png';

const styles = theme => ({
    select: {
        color: 'white',
        '&:before': {
            borderColor: 'transparent',
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '& .MuiSelect-icon': {
            color: 'white',
        },
    },
    windDirectionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
    },
    circle: {
        position: 'relative',
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '0.5rem',
    },
    windDirection: {
        position: 'absolute',
        fontSize: '0.6rem',
        color: 'white',
    },
    north: {
        top: '-2px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    south: {
        bottom: '-2px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    east: {
        right: '2px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    west: {
        left: '2px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    windDirectionArrow: {
        position: 'absolute',
        top: '48%',
        left: '52%',
        transform: 'translate(-50%, -50%) rotate(180deg)',
        transition: 'transform 0.3s ease-in-out',
        width: '1rem',
        height: '1.25rem',
    },
});

class DiaryClimate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            temperature: '',
            air_temperature: '',
            air_humidity: '',
            humidity: '',
            windSpeed: '',
            deltaT: '',
            windDirection: '',
            thermalInversion: '',
            pressure: '',
            datetime: '',
            loading: true,
            stations: [],
            selectedStation: '',
            date: Moment().format('YYYY-MM-DD'),
            intervalId: null,
            flightConditions: '',
        };
    }

    componentDidMount() {
        this.listStations().then(() => {
            if (this.state.stations.length > 0) {
                this.setState({ selectedStation: this.state.stations[0].id }, () => {
                    this.getDataById(this.state.stations[0].id);
                });
            }
        });

        const intervalId = setInterval(() => {
            if (this.state.selectedStation) {
                this.getDataById(this.state.selectedStation);
            }
        }, 300000);

        this.setState({ intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    listStations = () => {
        const apiUrl = `${Config.server}/weather_source`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt'),
            },
        };

        return fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then(
                (result) => {
                    this.setState({
                        stations: result,
                    });
                },
                (error) => {
                    console.error(error);
                }
            );
    };

    getDataById = (sourceID) => {
        const apiUrl = `${Config.server}/weather_data/${sourceID}/${this.state.date}`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access-token-jwt')}`,
                'Content-Type': 'application/json',
            },
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then((result) => {
                if (result.length > 0) {
                    const element = result[result.length - 1];
                    const deltaT = element.delta_t ? parseFloat(element.delta_t).toFixed(1) : '-';

                    let flightConditions = '';
                    if (deltaT >= 2 && deltaT <= 8) {
                        flightConditions = 'Condições adequadas';
                    } else if (deltaT > 10) {
                        flightConditions = 'Condições inadequadas';
                    } else {
                        flightConditions = 'Condições arriscadas';
                    }

                    const thermalInversion = element.air_temperature - 5 > element.temperature
                        ? 'Grandes chances de estar ocorrendo inversão térmica'
                        : element.air_temperature - 1 > element.temperature
                            ? 'Pode estar ocorrendo inversão térmica'
                            : 'Não está ocorrendo inversão térmica';

                    this.setState({
                        temperature: element.air_temperature ? `${element.air_temperature} °C` : '-',
                        air_temperature: element.air_temperature ? `${element.air_temperature}` : '-',
                        humidity: element.air_humidity ? `${element.air_humidity} %` : '-',
                        air_humidity: element.air_humidity ? `${element.air_humidity}` : '-',
                        windSpeed: element.wind_speed ? `${element.wind_speed} km/h` : '-',
                        deltaT: deltaT,
                        windDirection: element.wind_direction ? `${element.wind_direction} °` : null,
                        thermalInversion: thermalInversion,
                        pressure: element.pressure ? `${element.pressure} hPa` : '-',
                        datetime: element.datetime ? Moment(element.datetime).format("HH:mm  DD/MM/YYYY") : '-',
                        loading: false,
                        flightConditions: flightConditions,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false });
            });
    };

    handleStationChange = (event) => {
        const selectedStation = event.target.value;
        this.setState({ selectedStation, loading: true }, () => {
            this.getDataById(selectedStation);
        });
    };

    render() {
        const { classes, res } = this.props;

        const climateData = [
            { label: 'Temperatura', value: this.state.temperature },
            { label: 'Umidade', value: this.state.humidity },
            { label: 'Vel. do Vento', value: this.state.windSpeed },
            { label: 'Delta-T', value: this.state.deltaT },
            { label: 'Dir. do Vento', value: this.state.windDirection },
            { label: 'Pressão', value: this.state.pressure },
            { label: 'Inversão Térmica', value: this.state.thermalInversion },
        ];

        return (
            <div className='dashboard-card' style={{ minWidth: '10rem', minHeight: '10rem' }}>
                <div className='divContent'
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        borderRadius: '.8rem',
                        backgroundColor: '#343a40',
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div className='cardheader' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FilterDramaIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                            <span className='title' style={{ marginLeft: '0.5rem', color: 'white' }}>
                                {res.CLIMA_}
                            </span>
                        </div>
                        <FormControl>
                            <Select
                                labelId="station-select-label"
                                value={this.state.selectedStation}
                                onChange={this.handleStationChange}
                                className={classes.select}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            zIndex: 2000,
                                        },
                                    },
                                    PopperProps: {
                                        disablePortal: false,
                                        modifiers: {
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement: 'viewport',
                                            },
                                        },
                                    },
                                }}
                            >
                                {this.state.stations.map((station) => (
                                    <MenuItem key={station.id} value={station.id}>
                                        {station.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='cardmiddle' style={{ overflow: 'auto' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '0.5rem', width: '100%' }}>
                            {climateData.map((data, index) => {
                                if (data.label === 'Inversão Térmica') {
                                    const thermalInversionColor = data.value === 'Grandes chances de estar ocorrendo inversão térmica'
                                        ? 'red'
                                        : data.value === 'Pode estar ocorrendo inversão térmica'
                                            ? 'yellow'
                                            : 'lawngreen';

                                    return (
                                        <Tooltip key={index} title={
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '4px',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                    width: '25.25rem'
                                                }}
                                            >
                                                {data.value === "Não está ocorrendo inversão térmica"
                                                    ?
                                                    <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={normalFlowExample} alt="Fluxo normal" />
                                                    :
                                                    <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={thermalInversionExample} alt="Fluxo com inversão térmica" />
                                                }
                                            </div>
                                        }
                                            placement="top"
                                            arrow
                                            classes={{ tooltip: classes.customWidthTooltip }}
                                        >
                                            <div style={{ gridColumn: 'span 3', borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                                <span style={{ display: 'block', color: '#fa7d34' }}>
                                                    {data.label}
                                                </span>
                                                <span style={{ display: 'flex', justifyContent: 'center', color: thermalInversionColor, fontWeight: 'bold', fontSize: '1.25rem' }}>
                                                    {this.state.loading ? <Skeleton width="12rem" /> : data.value}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    );
                                }

                                if (data.label === 'Delta-T') {
                                    let deltaTColor = 'white';
                                    if (this.state.deltaT >= 2 && this.state.deltaT <= 8) {
                                        deltaTColor = 'lawngreen';
                                    } else if (this.state.deltaT > 10) {
                                        deltaTColor = 'red';
                                    } else {
                                        deltaTColor = 'yellow';
                                    }

                                    return (
                                        <Tooltip key={index} title={
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '4px',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                    width: '22.5rem'
                                                }}
                                            >
                                                <DeltaTHeat
                                                    atual={this.state.deltaT}
                                                    humidade={this.state.air_humidity}
                                                    temperatura={this.state.air_temperature}
                                                />
                                            </div>
                                        }
                                            placement="top"
                                            arrow
                                            classes={{ tooltip: classes.customWidthTooltip }}
                                        >
                                            <div style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                                <span style={{ display: 'block', color: '#fa7d34' }}>
                                                    {data.label}
                                                </span>
                                                <span style={{ display: 'flex', justifyContent: 'center', color: deltaTColor, fontWeight: 'bold', fontSize: '1.25rem' }}>
                                                    {this.state.loading ? <Skeleton width="4rem" /> : data.value}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    );
                                }

                                if (data.label === 'Dir. do Vento') {
                                    return (
                                        <div key={index} className={classes.windDirectionContainer} style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                            <span style={{ display: 'block', color: '#fa7d34' }}>{data.label}</span>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {this.state.windDirection ? (
                                                    <span style={{ color: 'white', fontWeight: 'bold', fontSize: '1.25rem', margin: '0.5rem', whiteSpace: 'nowrap' }}>
                                                        {data.value}
                                                    </span>
                                                ) : (
                                                    <Skeleton width="2rem" />
                                                )}
                                                {this.state.windDirection ? (
                                                    <div className={classes.circle}>
                                                        <div className={`${classes.windDirection} ${classes.north}`}>N</div>
                                                        <div className={`${classes.windDirection} ${classes.south}`}>S</div>
                                                        <div className={`${classes.windDirection} ${classes.east}`}>L</div>
                                                        <div className={`${classes.windDirection} ${classes.west}`}>O</div>
                                                        <img
                                                            src={windArrowImage}
                                                            alt="Direção do vento"
                                                            className={classes.windDirectionArrow}
                                                            style={{ transform: `translate(-50%, -50%) rotate(${parseFloat(this.state.windDirection) + 180}deg)` }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Skeleton variant="rect" width="1rem" height="1rem" className={classes.windDirectionArrow} />
                                                )}
                                            </div>
                                        </div>
                                    );
                                }

                                return (
                                    <div key={index} style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                        <span style={{ display: 'block', color: '#fa7d34' }}>
                                            {data.label}
                                        </span>
                                        <span style={{ display: 'flex', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: '1.25rem' }}>
                                            {this.state.loading ? <Skeleton width="4rem" /> : data.value}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='cardfooter'>
                        <span className='cardfooter-content' style={{ paddingTop: '.7rem' }}>
                            <span style={{ display: 'inline-table', paddingLeft: '1rem', width: '50%' }}>
                                <span style={{ display: 'table-row', width: '100%', color: '#fa7d34' }}>
                                    {res.ATUALIZADO}
                                </span>
                            </span>
                            <span style={{ display: 'inline-table', paddingRight: '1rem', width: '50%', fontSize: '1.25rem', fontWeight: 'bold', color: 'white' }}>
                                <span style={{ display: 'table-row', width: '100%' }}>
                                    {this.state.loading ? <Skeleton width="10rem" /> : this.state.datetime}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(DiaryClimate);