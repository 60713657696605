import React from 'react';
import AircraftIcon from '../icons/aircraftIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';
import Moment from 'moment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

class DiaryAircraftStatusList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAircraft: 0,
      aircraftData: [],
    };
  }

  componentDidMount() {
    this.updateAircraftCount();
    this.handleJobData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.aircraft !== this.props.aircraft || prevProps.jobs !== this.props.jobs) {
      this.updateAircraftCount();
      this.handleJobData();
    }
  }

  updateAircraftCount = () => {
    const totalAircraft = Array.isArray(this.props.aircraft) ? this.props.aircraft.length : 0;
    this.setState({ totalAircraft });
  };

  isAircraftOnline = (pingTime) => {
    if (!pingTime) {
      return false;
    }

    const now = Moment();
    const pingMoment = Moment(pingTime);
    return now.diff(pingMoment, 'minutes') <= 3;
  };

  getLastUpdate = (pingTime) => {
    if (!pingTime) {
      return "-";
    }
    return Moment(pingTime).format('DD/MM/YYYY HH:mm');
  };

  handleJobData = () => {
    const { aircraft, jobs } = this.props;

    let aircraftMap = Array.isArray(aircraft)
      ? aircraft.map((plane) => ({
        ...plane,
        totalSprayed: 0,
      }))
      : [];

    let otherAircraft = { name: 'Outros', totalSprayed: 0, svg: null };

    if (Array.isArray(jobs)) {
      jobs.forEach((job) => {
        if (job.list_exe && job.list_exe.length > 0) {
          const sprayedArea = job.list_exe[0].area_spray ? job.list_exe[0].area_spray : 0;

          if (job.list_exe[0].id_plane) {
            const plane = aircraftMap.find((plane) => plane.id === job.list_exe[0].id_plane);
            if (plane) {
              plane.totalSprayed += sprayedArea;
            }
          } else {
            otherAircraft.totalSprayed += sprayedArea;
          }
        }
      });
    }

    if (otherAircraft.totalSprayed > 0) {
      aircraftMap.push(otherAircraft);
    }

    aircraftMap = aircraftMap.sort((a, b) => {
      if (a.totalSprayed === 0 && b.totalSprayed === 0) {
        const aTime = a.ping_time ? new Date(a.ping_time).getTime() : 0;
        const bTime = b.ping_time ? new Date(b.ping_time).getTime() : 0;
        return bTime - aTime;
      } else if (a.totalSprayed === 0) {
        return 1;
      } else if (b.totalSprayed === 0) {
        return -1;
      } else {
        if (b.totalSprayed !== a.totalSprayed) {
          return b.totalSprayed - a.totalSprayed;
        } else {
          const aTime = a.ping_time ? new Date(a.ping_time).getTime() : 0;
          const bTime = b.ping_time ? new Date(b.ping_time).getTime() : 0;
          return bTime - aTime;
        }
      }
    });

    this.setState({ aircraftData: aircraftMap });
  };

  render() {
    const { res } = this.props;
    const { aircraftData } = this.state;

    return (
      <div className="dashboard-card" style={{ minWidth: '10rem', minHeight: '10rem', display: 'flex', flexDirection: 'column' }}>
        <div
          className="divContent"
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            borderRadius: '.8rem',
            backgroundColor: '#343a40',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            position: 'relative',
            paddingBottom: '1rem'
          }}
        >
          <div className="cardheader">
            <SvgIcon viewBox="0 0 800 800" style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }}>
              <path d="M 0 156.81 L 0 0 L 65.28 0 L 125.41 103.72 L 243.59 87.54 C 260.71 67.18 282.02 49.29 307.72 33.88 C 333.41 18.46 354.72 10.66 371.85 10.66 L 381.93 10.66 C 395.25 10.28 409.14 19.04 423.8 36.54 C 438.26 54.24 448.73 67.56 455.58 76.51 L 734.36 95.92 L 734.36 21.32 L 753.2 21.32 L 753.2 116.66 C 757.39 116.66 762.34 117.61 768.62 119.7 C 774.71 121.8 777.75 123.89 777.75 126.17 C 777.75 128.65 774.71 132.07 768.62 136.45 C 762.34 140.64 757.58 142.73 753.77 142.73 L 753.77 242.06 L 734.74 242.06 L 734.74 168.99 L 569.18 208 L 569.18 251.01 C 583.46 256.15 590.5 266.23 590.5 281.08 C 590.5 302.58 579.84 313.43 558.72 313.43 C 537.02 313.43 526.37 302.58 526.37 281.08 C 526.37 265.47 533.98 255.19 549.2 250.44 L 549.2 210.29 L 501.82 225.89 L 384.78 225.89 L 0 156.81 Z M 345.58 76.51 L 418.66 76.51 L 382.5 39.21 L 345.58 39.21 L 345.58 76.51 Z"></path>
            </SvgIcon>
            <span className="title">{res.AERONAVES_}</span>
          </div>
          <div className="cardmiddle" style={{ flexGrow: 1, overflowY: 'auto', maxHeight: 'calc(100% - 5rem)', paddingRight: '1rem' }}>
            <div style={{ display: 'block', width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0', color: 'white', fontWeight: 'bold' }}>
                <span style={{ width: '30%' }}>{res.AERONAVE}</span>
                <span style={{ width: '30%', textAlign: 'center' }}>{res.HECTARES_PULVERIZADOS}</span>
                <span style={{ width: '40%', textAlign: 'center' }}>{res.ULTIMA_ATUALIZACAO}</span>
              </div>
              {aircraftData.map((plane, i) => {
                const hectares = plane.totalSprayed ? `${plane.totalSprayed} ha` : '-';
                const isOnline = this.isAircraftOnline(plane.ping_time);

                return (
                  <span key={i} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '.5rem', paddingBottom: i === aircraftData.length - 1 ? '1rem' : '0' }}>
                    <span style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
                      <FiberManualRecordIcon style={{ color: isOnline ? '#32CD32' : '#D3D3D3', marginLeft: '.5rem' }} />
                      <span style={{ width: '2.5rem', height: '2.5rem', padding: '.2rem', backgroundColor: '#FFF', borderRadius: '.4rem', marginLeft: '.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {plane.svg ? (
                          <AircraftIcon aircraft={{ svg: plane.svg }} width={30} height={30} />
                        ) : plane.name === 'Outros' ? (
                          <AirplanemodeInactiveIcon style={{ color: '#D3D3D3', fontSize: 30 }} />
                        ) : (
                          <AirplanemodeInactiveIcon style={{ color: '#D3D3D3', fontSize: 30 }} />
                        )}
                      </span>
                      <span style={{ paddingLeft: '.5rem', color: 'white', display: 'flex', alignItems: 'center' }}>
                        {plane.name}
                      </span>
                    </span>
                    <span style={{ width: '30%', textAlign: 'center', color: '#D3D3D3', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                      {hectares}
                    </span>
                    <span style={{ width: '40%', textAlign: 'center', color: 'white', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                      {this.getLastUpdate(plane.ping_time)}
                    </span>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="cardfooter">
            <span className="cardfooter-content" style={{ paddingTop: '.7rem' }}>
              <span style={{ display: 'inline-table', paddingLeft: '1rem', width: '50%' }}>
                <span style={{ display: 'table-row', width: '100%', color: '#fa7d34' }}>{res.TOTAL_AERONAVES}</span>
              </span>
              <span style={{ display: 'inline-table', paddingRight: '1rem', width: '50%', fontSize: '1rem', fontWeight: 'bold' }}>
                <span style={{ display: 'table-row', width: '100%' }}>{this.state.totalAircraft}</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default DiaryAircraftStatusList;