import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Config from '../../constants/appConfig';

const styles = theme => ({
    tableContainer: {
        overflowY: 'auto',
        maxHeight: 'calc(100% - 2rem)',
    },
    table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
    },
    tableRow: {
        backgroundColor: theme.palette.background.paper,
        '&:nth-of-type(odd)': {
            backgroundColor: '#343a40',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#343a40',
        },
        '& > *': {
            padding: '0.5rem',
            borderBottom: '2px solid #ff8c00',
        },
    },
    tableCell: {
        textAlign: 'center',
        color: 'white',
        wordBreak: 'break-word',
    },
    tableHeadCell: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#fa7d34',
        backgroundColor: '#343a40',
        borderBottom: '1px solid #fa7d34',
        padding: '0.75rem',
    },
    cardmiddle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
    },
});

const renderAlertIcon = (type, title, classes) => {
    switch (type) {
        case 'DGPS':
            return (
                <div className={classes.iconWrapper} style={{ backgroundColor: 'green' }}>
                    <PowerSettingsNewIcon style={{ color: 'white' }} />
                </div>
            );
        case 'relatorio':
            return (
                <div className={classes.iconWrapper} style={{ backgroundColor: '#1E90FF' }}>
                    <InsertDriveFileIcon style={{ color: 'white' }} />
                </div>
            );
        case 'transferencia':
            return (
                <div className={classes.iconWrapper} style={{ backgroundColor: '#0000CD' }}>
                    <ImportExportIcon style={{ color: 'white' }} />
                </div>
            );
        case 'job':
            if (title.includes('Fim de Trabalho')) {
                return (
                    <div className={classes.iconWrapper} style={{ backgroundColor: '#FF6347' }}>
                        <FlightLandIcon style={{ color: 'white' }} />
                    </div>
                );
            } else if (title.includes('Inicio de Trabalho')) {
                return (
                    <div className={classes.iconWrapper} style={{ backgroundColor: '#32CD32' }}>
                        <FlightTakeoffIcon style={{ color: 'white' }} />
                    </div>
                );
            }
            return null;
        default:
            return null;
    }
};

class DiaryAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.checkAlert();
    }

    checkAlert = () => {
        const startDate = Moment().startOf('day').valueOf();
        const endDate = Moment().endOf('day').valueOf();

        const apiUrl = `${Config.server}/alert/date/${startDate}/${endDate}`;
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200) throw new Error(res.status);
                return res.json();
            })
            .then((result) => {
                if (Array.isArray(result)) {
                    this.setState({ alerts: result, isLoading: false });
                }
            })
            .catch((error) => {
                console.error(error);
            });

        setTimeout(() => {
            this.checkAlert();
        }, 300000);
    };

    renderTable = () => {
        const { classes, res } = this.props;
        const { alerts } = this.state;

        return (
            <div className={classes.tableContainer}>
                <Table className={classes.table} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>{res.TIPO}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.TITULO}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.DESCRICAO}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.DATA}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alerts.map(alert => (
                            <TableRow key={alert.id} className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    {renderAlertIcon(alert.type, alert.title, classes)}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{alert.title}</TableCell>
                                <TableCell className={classes.tableCell}>{alert.text}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    {Moment(alert.created).format('DD/MM/YYYY HH:mm')}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    };

    render() {
        const { classes, res } = this.props;
        const { isLoading } = this.state;

        return (
            <div className='dashboard-card' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className='divContent'
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        borderRadius: '.8rem',
                        backgroundColor: '#343a40',
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        flexGrow: 1
                    }}>
                    <div className='cardheader'>
                        <NotificationsActiveIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                        <span className='title'>
                            {res.ALERTAS_}
                        </span>
                    </div>
                    <div className={classes.cardmiddle}>
                        {isLoading ? (
                            <Skeleton variant="rect" width="100%" height={200} />
                        ) : (
                            this.renderTable()
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

DiaryAlert.propTypes = {
    classes: PropTypes.object.isRequired,
    res: PropTypes.object.isRequired,
};

export default withStyles(styles)(DiaryAlert);