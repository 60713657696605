import React from 'react';
import PropTypes from 'prop-types';
import { DashboardSharp } from '@material-ui/icons';
import Config from '../../constants/appConfig';
import RGL, { WidthProvider } from "react-grid-layout";
import tools from '../../components/util/tools';
import { withStyles } from '@material-ui/core/styles';

import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import BallotIcon from '@material-ui/icons/Ballot';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import DiarySumary from '../../components/custom_dashboard/diarySummary';
import DiaryExecuting from '../../components/custom_dashboard/diaryExecuting';
import DiaryComplete from '../../components/custom_dashboard/diaryComplete';
import DiaryAircraftStatusList from '../../components/custom_dashboard/diaryAircraftStatusList';
import DiaryMap from '../../components/custom_dashboard/diaryMap';
import DiaryClimate from '../../components/custom_dashboard/diaryClimate';
import DiaryQuality from '../../components/custom_dashboard/diaryQuality';
import DiaryJob from '../../components/custom_dashboard/diaryJob';
import DiaryApplications from '../../components/custom_dashboard/diaryApplications';
import DiaryOperation from '../../components/custom_dashboard/diaryOperation';
import DiaryLocation from '../../components/custom_dashboard/diaryLocation';
import DiaryAlert from '../../components/custom_dashboard/diaryAlert';
import TransferList from '../../components/custom_dashboard/transferList';

const styles = theme => ({
  expandedMapStyles: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1100,
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#FFF'
  },
  cardContainer: {
    position: 'relative',
    minHeight: '190px',
    minWidth: '275px',
  }
});

const ReactGridLayout = WidthProvider(RGL);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cardComponentMapping = {
  'Resumo Diário': 'DiarySumary',
  'Em Execução': 'DiaryExecuting',
  'Finalizados': 'DiaryComplete',
  'Aeronaves': 'DiaryAircraftStatusList',
  'Mapa Diário': 'DiaryMap',
  'Clima': 'DiaryClimate',
  'Qualidade': 'DiaryQuality',
  'Trabalhos': 'DiaryJob',
  'Aplicações': 'DiaryApplications',
  'Ciclo de Operação': 'DiaryOperation',
  'Localização': 'DiaryLocation',
  'Alertas': 'DiaryAlert',
};

const cardSizeDefaults = {
  'Resumo Diário': { w: 5, h: 5 },
  'Em Execução': { w: 5, h: 5 },
  'Finalizados': { w: 5, h: 5 },
  'Aeronaves': { w: 5, h: 5 },
  'Mapa Diário': { w: 6, h: 5 },
  'Clima': { w: 4, h: 3 },
  'Qualidade': { w: 5, h: 4 },
  'Trabalhos': { w: 5, h: 4 },
  'Aplicações': { w: 5, h: 4 },
  'Ciclo de Operação': { w: 5, h: 4 },
  'Localização': { w: 5, h: 4 },
  'Alertas': { w: 5, h: 4 },
};

class Dashboard extends React.Component {
  state = {
    dateIni: this.formatDateIni(new Date()),
    dateEnd: this.formatDateEnd(new Date()),
    clock: new Date(),
    jobs: [],
    aircraft: [],
    airplanes: [],
    fullScreen: false,
    showConfig: false,
    selectedCards: [],
    availableCards: ['Resumo Diário', 'Em Execução', 'Finalizados', 'Aeronaves', 'Mapa Diário', 'Clima', 'Qualidade', 'Trabalhos', 'Aplicações', 'Ciclo de Operação', 'Localização', 'Alertas'],
    layout: [],
    id_company: null,
    id_config: null,
    diaryMapStatic: false,
    userInteracted: false,
    savedLayouts: [],
    currentLayoutName: '',
    minCardSize: { w: 3, h: 5 },
    updateIntervalId: null,
    creatingNewLayout: false,
    isLocked: true,
    jobList: [],
    loading: false
  };

  clockInterval;
  debounceSave = null;

  componentDidMount() {
    this.clockInterval = setInterval(() => {
      this.setState({ clock: new Date() })
    }, 1000);

    this.updateList(1, true, null);
    this.handleAirplanes();

    const updateIntervalId = setInterval(() => {
      this.updateList(1, true, null);
      this.handleAirplanes();
    }, 300000);

    this.setState({ updateIntervalId });
    this.loadDashboardConfig();
  }

  componentWillUnmount() {
    if (this.state.userInteracted) {
      this.saveDashboardConfig();
    }
    clearInterval(this.clockInterval);
    clearInterval(this.state.updateIntervalId);
  }

  formatDateIni(date) {
    const dateString = date.toISOString().split('T')[0];
    return `${dateString} 00:00:00`;
  }

  formatDateEnd(date) {
    const dateString = date.toISOString().split('T')[0];
    return `${dateString} 23:59:59`;
  }

  updateList = (page, change, id) => {
    this.setState({ loading: true });
    const size = 20;

    const filters = {
      size: size,
      page: page,
      dateini: new Date(this.state.dateIni).getTime(),
      datefim: new Date(this.state.dateEnd).getTime(),
      type: this.state.tab_type || [],
      state: this.state.tab_state || [],
      missing: this.state.tab_missing || [],
      planes: this.state.selectedPlanes || [],
      id: id,
      filtro: this.state.filtro || "",
      referencia: this.state.referencia || "started",
    };

    const apiUrl = `${Config.server}/job_list`;

    let options = {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(filters),
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            jobList: result.data,
            loading: false,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
  };

  handleAirplanes = () => {
    const apiUrl = `${Config.server}/plane_jobs`;
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('access-token-jwt'),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const airplanes = data.planes;
        this.setState({ airplanes });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  loadDashboardConfig = () => {
    const apiUrl = `${Config.server}/dashboard_config`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          const config = data[0];

          let layoutConfig;
          try {
            layoutConfig = JSON.parse(config.layout_config);
            if (typeof layoutConfig === 'string') {
              layoutConfig = JSON.parse(layoutConfig);
            }
          } catch (error) {
            return;
          }

          const selectedScreen = localStorage.getItem('selectedScreen');
          const firstLayout = selectedScreen || layoutConfig.savedLayouts?.[0]?.name;

          this.setState({
            savedLayouts: layoutConfig.savedLayouts || [],
            id_company: config.id_company,
            id_config: config.id,
          }, () => {
            if (firstLayout && !this.state.creatingNewLayout) {
              this.loadLayout(firstLayout);
            }
          });
        }
      })
      .catch(error => {
        if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
        else {
          console.log(error);
        }
      });
  };

  saveDashboardConfig = () => {
    const apiUrl = this.state.id_config ? `${Config.server}/dashboard_config/${this.state.id_config}` : `${Config.server}/dashboard_config`;
    const method = this.state.id_config ? 'PUT' : 'POST';

    const newLayouts = this.state.savedLayouts.filter(l => l.name !== this.state.currentLayoutName);
    newLayouts.push({
      name: this.state.currentLayoutName,
      layout: this.state.layout,
      selectedCards: this.state.selectedCards,
    });

    const data = {
      id_company: this.state.id_company,
      layout_config: JSON.stringify({ savedLayouts: newLayouts })
    };

    fetch(apiUrl, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('access-token-jwt')
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (!this.state.id_config && method === 'POST') {
          this.setState({ id_config: data.id });
        }

        if (this.state.currentLayoutName) {
          localStorage.setItem('selectedScreen', this.state.currentLayoutName);
        }

        this.setState({
          savedLayouts: newLayouts,
          currentLayoutName: this.state.currentLayoutName || newLayouts[0]?.name || ''
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleSaveAndClose = () => {
    this.saveDashboardConfig();
    this.toggleConfig();
  };

  handleCardSelectionChange = (selected) => {
    const selectedCardKeys = selected.map(name =>
      Object.keys(cardComponentMapping).find(key => key === name)
    );

    this.setState({ selectedCards: selectedCardKeys });
  };

  onGridLayoutChange = (layout) => {
    if (this.state.isLocked) return;

    const updatedLayout = layout.map((item) => {
      const cardSize = cardSizeDefaults[item.i] || this.state.minCardSize;
      return {
        ...item,
        minW: cardSize.w,
        minH: cardSize.h,
        w: item.w < cardSize.w ? cardSize.w : item.w,
        h: item.h < cardSize.h ? cardSize.h : item.h
      };
    });

    this.setState({ layout: updatedLayout });
  };

  onCardInteraction = () => {
    this.setState({ userInteracted: true });
  };

  toggleConfig = () => {
    this.setState(prevState => ({
      showConfig: !prevState.showConfig,
      currentLayoutName: prevState.showConfig ? prevState.currentLayoutName : prevState.currentLayoutName,
      layout: prevState.showConfig ? prevState.layout : prevState.layout,
      selectedCards: prevState.showConfig ? prevState.selectedCards : prevState.selectedCards,
    }));
  };

  toggleFullScreen = () => {
    this.setState(prevState => ({ fullScreen: !prevState.fullScreen }));
  };

  toggleLock = () => {
    this.setState(prevState => {
      const newLockState = !prevState.isLocked;
      if (newLockState) {
        this.saveDashboardConfig();
      }
      return { isLocked: newLockState };
    });
  };

  diaryMapMouseEvent = (isOverMap) => {
    this.setState({ diaryMapStatic: isOverMap });
  };

  handleLayoutNameChange = (event) => {
    this.setState({ currentLayoutName: event.target.value });
  };

  loadLayout = (layoutName) => {
    if (this.state.userInteracted) {
      this.saveDashboardConfig();
      this.setState({ userInteracted: false });
    }

    const layout = this.state.savedLayouts.find(l => l.name === layoutName);
    if (layout) {
      const updatedLayout = layout.layout.map((item) => {
        const cardSize = cardSizeDefaults[item.i] || this.state.minCardSize;
        return {
          ...item,
          w: Math.max(item.w, cardSize.w),
          h: Math.max(item.h, cardSize.h),
          minW: cardSize.w,
          minH: cardSize.h,
        };
      });

      this.setState({
        currentLayoutName: layout.name,
        layout: updatedLayout,
        selectedCards: layout.selectedCards,
      });

      localStorage.setItem('selectedScreen', layout.name);
    }
  };

  handleClearSelection = () => {
    this.setState({
      currentLayoutName: '',
      layout: [],
      selectedCards: [],
    });
  };

  createNewLayout = () => {
    this.setState({
      currentLayoutName: '',
      layout: [],
      selectedCards: [],
      showConfig: true,
      creatingNewLayout: true,
    });
  };

  deleteLayout = (layoutName) => {
    const newLayouts = this.state.savedLayouts.filter(l => l.name !== layoutName);

    const apiUrl = `${Config.server}/dashboard_config/${this.state.id_config}`;

    this.setState({ savedLayouts: newLayouts }, () => {
      const data = {
        id_company: this.state.id_company,
        layout_config: JSON.stringify({ savedLayouts: newLayouts })
      };

      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('access-token-jwt')
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.json();
        })
        .then(() => {
          if (this.state.currentLayoutName === layoutName) {
            if (newLayouts.length > 0) {
              this.loadLayout(newLayouts[0].name);
            } else {
              this.handleClearSelection();
            }
          }
        })
        .catch(error => {
          this.setState({ savedLayouts: this.state.savedLayouts });
          console.error(error);
        });
    });
  };

  render() {
    const { classes, res } = this.props;

    return (
      <div className={this.state.fullScreen ? classes.expandedMapStyles : ''}>
        <div className="divContent">
          <div className="containerTitle">
            <div><h1 style={{ margin: '0' }}>{res.DASHBOARD_ONLINE}</h1></div>
            <div className="centerTop"><h1 style={{ textAlign: 'center', margin: '0' }}>{tools.dateFormatWithSeconds(this.state.clock, this.props.res.LINGUA)}</h1></div>
            <div>
              <Button title='Adicionar Relatórios' variant="contained" color="primary" onClick={this.toggleConfig}>
                <BallotIcon />
              </Button> &nbsp;
              <Button title={!this.state.fullScreen ? 'Expandir Tela' : 'Minimizar Tela'} variant="contained" color="primary" onClick={this.toggleFullScreen}>
                {this.state.fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Button>
              &nbsp;
              <Button title={!this.state.isLocked ? 'Bloquear Edição' : 'Habilitar Edição'} variant="contained" color="primary" onClick={this.toggleLock}>
                {this.state.isLocked ? <LockIcon /> : <LockOpenIcon />}
              </Button>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.showConfig}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.toggleConfig}
          fullWidth
          maxWidth="sm"
          disablePortal
        >
          <DialogTitle style={{
            backgroundColor: '#2D3238',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem 1.5rem',
            position: 'relative',
          }}
          >
            <div style={{ color: 'white', fontSize: '1.25rem' }}>
              {res.CONFIGURAR_DASHBOARD}
            </div>
            <Button
              size="small"
              onClick={this.toggleConfig}
              style={{
                color: 'white',
                position: 'absolute',
                right: '1rem',
                top: '1rem'
              }}
            >
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: '#FFF', padding: '1.5rem' }}>
            <div style={{ marginBottom: '1rem' }}>
              <TextField
                fullWidth
                value={this.state.currentLayoutName}
                onChange={this.handleLayoutNameChange}
                placeholder="Nome da Tela"
              />
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <h4>{res.TELAS_SALVAS}</h4>
              {this.state.savedLayouts.map(layout => (
                <div key={layout.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Button
                    onClick={() => this.loadLayout(layout.name)}
                    color="primary"
                    style={{ marginRight: '10px' }}
                  >
                    {layout.name}
                  </Button>
                  {this.state.currentLayoutName === layout.name && (
                    <IconButton
                      onClick={() => this.deleteLayout(layout.name)}
                      color="secondary"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <Button variant="contained" color="primary" onClick={this.createNewLayout} fullWidth>
                {res.NOVA_TELA}
              </Button>
            </div>

            <TransferList
              availableCards={this.state.availableCards}
              selectedCards={this.state.selectedCards}
              cardComponentMapping={cardComponentMapping}
              onSelectionChange={this.handleCardSelectionChange}
            />
          </DialogContent>
          <DialogActions style={{ padding: '1rem', justifyContent: 'flex-end', backgroundColor: '#FFF' }}>
            <Button onClick={this.handleClearSelection} color="secondary">
              {res.LIMPAR_TELA}
            </Button>
            <Button onClick={this.handleSaveAndClose} color="primary">
              {res.SALVAR_TELA}
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ position: 'relative', marginTop: '.3rem' }}>
          <div className='maxVH'>
            <ReactGridLayout
              className="layout"
              cols={12}
              layout={this.state.layout}
              rowHeight={30}
              width={1200}
              verticalCompact={true}
              compactType="vertical"
              preventCollision={false}
              onLayoutChange={this.onGridLayoutChange}
              onDragStart={this.onCardInteraction}
              onResizeStart={this.onCardInteraction}
              margin={[10, 10]}
              useCSSTransforms={true}
              isDraggable={!this.state.diaryMapStatic && !this.state.isLocked}
              isResizable={!this.state.diaryMapStatic && !this.state.isLocked}
            >
              {this.state.selectedCards.includes('Resumo Diário') && (
                <div key="diarySumary" className={classes.cardContainer}>
                  <DiarySumary jobs={this.state.jobList} res={res} />
                </div>
              )}

              {this.state.selectedCards.includes('Em Execução') && (
                <div key="diaryExecuting" className={classes.cardContainer}>
                  <DiaryExecuting jobs={this.state.jobList} res={res} />
                </div>
              )}

              {this.state.selectedCards.includes('Finalizados') && (
                <div key="diaryComplete" className={classes.cardContainer}>
                  <DiaryComplete jobs={this.state.jobList} res={res} />
                </div>
              )}

              {this.state.selectedCards.includes('Aeronaves') && (
                <div key="diaryAircraftStatusList" className={classes.cardContainer}>
                  <DiaryAircraftStatusList jobs={this.state.jobList} aircraft={this.state.airplanes} res={res} />
                </div>
              )}

              {this.state.selectedCards.includes('Mapa Diário') && (
                <div key="diaryMap" className={classes.cardContainer}>
                  <DiaryMap jobs={this.state.jobList} res={res} ommouseovermap={this.diaryMapMouseEvent} />
                </div>
              )}

              {this.state.selectedCards.includes('Clima') && (
                <div key="diaryClimate" className={classes.cardContainer}>
                  <DiaryClimate res={res} />
                </div>
              )}
              {this.state.selectedCards.includes('Qualidade') && (
                <div key="diaryQuality" className={classes.cardContainer}>
                  <DiaryQuality res={res} />
                </div>
              )}
              {this.state.selectedCards.includes('Trabalhos') && (
                <div key="diaryJob" className={classes.cardContainer}>
                  <DiaryJob jobs={this.state.jobList} aircraft={this.state.airplanes} res={res} />
                </div>
              )}
              {this.state.selectedCards.includes('Aplicações') && (
                <div key="diaryApplications" className={classes.cardContainer}>
                  <DiaryApplications jobs={this.state.jobList} res={res} />
                </div>
              )}
              {this.state.selectedCards.includes('Ciclo de Operação') && (
                <div key="diaryOperation">
                  <DiaryOperation jobs={this.state.jobList} res={res} />
                </div>
              )}
              {this.state.selectedCards.includes('Localização') && (
                <div key="diaryLocation">
                  <DiaryLocation airplanes={this.state.airplanes} res={res} />
                </div>
              )}
              {this.state.selectedCards.includes('Alertas') && (
                <div key="diaryAlert">
                  <DiaryAlert res={res} />
                </div>
              )}
            </ReactGridLayout>
          </div>
        </div>
      </div>
    );
  }
}

DashboardSharp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Dashboard);

const dashboardDiaryBox = (props) => {
  const { res } = props;
  return (
    <div className="rowForm">
      <TextFields1 res={res} />
    </div>)
}

export default dashboardDiaryBox;